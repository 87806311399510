define("ember-drag-sort/templates/components/drag-sort-list", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.items as |item index|}}
    <DragSortItem
      @additionalArgs                 = {{this.additionalArgs}}
      @item                           = {{item}}
      @index                          = {{index}}
      @items                          = {{this.items}}
      @group                          = {{this.group}}
      @handle                         = {{this.handle}}
      @tagName                        = {{this.childTagName}}
      @isHorizontal                   = {{this.isHorizontal}}
      @isRtl                          = {{this.isRtl}}
      @draggingEnabled                = {{this.draggingEnabled}}
      @sourceOnly                     = {{this.sourceOnly}}
  
      @dragEndAction                  = {{this.dragEndAction}}
      @dragStartAction                = {{this.dragStartAction}}
      @determineForeignPositionAction = {{this.determineForeignPositionAction}}
  
      class = {{this.childClass}}
    >
      {{yield item index}}
    </DragSortItem>
  {{/each}}
  
  */
  {
    "id": "sj2McIAn",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],[[16,0,[30,0,[\"childClass\"]]]],[[\"@additionalArgs\",\"@item\",\"@index\",\"@items\",\"@group\",\"@handle\",\"@tagName\",\"@isHorizontal\",\"@isRtl\",\"@draggingEnabled\",\"@sourceOnly\",\"@dragEndAction\",\"@dragStartAction\",\"@determineForeignPositionAction\"],[[30,0,[\"additionalArgs\"]],[30,1],[30,2],[30,0,[\"items\"]],[30,0,[\"group\"]],[30,0,[\"handle\"]],[30,0,[\"childTagName\"]],[30,0,[\"isHorizontal\"]],[30,0,[\"isRtl\"]],[30,0,[\"draggingEnabled\"]],[30,0,[\"sourceOnly\"]],[30,0,[\"dragEndAction\"]],[30,0,[\"dragStartAction\"]],[30,0,[\"determineForeignPositionAction\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[18,3,[[30,1],[30,2]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1,2]],null]],[\"item\",\"index\",\"&default\"],false,[\"each\",\"-track-array\",\"drag-sort-item\",\"yield\"]]",
    "moduleName": "ember-drag-sort/templates/components/drag-sort-list.hbs",
    "isStrictMode": false
  });
  _exports.default = _default;
});