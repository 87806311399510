define("ember-models-table/components/models-table/themes/bootstrap4/global-filter", ["exports", "@ember/component", "@ember/template-factory", "ember-models-table/components/models-table/themes/default/global-filter"], function (_exports, _component, _templateFactory, _globalFilter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{@themeInstance.globalFilterWrapper}}">
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      <div class="globalSearch {{@themeInstance.form}}">
        <div class={{@themeInstance.inputGroup}}>
          <label for={{this.inputId}} class="input-group-addon">{{@themeInstance.searchLabelMsg}}</label>
          <Input
            id={{this.inputId}}
            @type="text"
            @value={{@value}}
            class="filterString {{@themeInstance.input}}"
            @enter={{this.noop}}
            {{on "input" this.updateGlobalFilterString}}
            placeholder={{@themeInstance.searchPlaceholderMsg}}/>
          <span class="input-group-btn">
            <button
              class="clearFilterIcon {{@themeInstance.buttonDefaultSmall}}"
              disabled={{unless @globalFilterUsed "disabled"}}
              {{on "click" this.clearGlobalFilter}}
              type="button">
              <i class={{@themeInstance.clearFilterIcon}}></i>
              <span class="emt-sr-only">{{@themeInstance.clearGlobalFilterMsg}}</span>
            </button>
          </span>
        </div>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "CqQX/AEi",
    "block": "[[[10,0],[15,0,[29,[[30,1,[\"globalFilterWrapper\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[29,[\"globalSearch \",[30,1,[\"form\"]]]]],[12],[1,\"\\n      \"],[10,0],[15,0,[30,1,[\"inputGroup\"]]],[12],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[14,0,\"input-group-addon\"],[12],[1,[30,1,[\"searchLabelMsg\"]]],[13],[1,\"\\n        \"],[8,[39,3],[[16,1,[30,0,[\"inputId\"]]],[16,0,[29,[\"filterString \",[30,1,[\"input\"]]]]],[16,\"placeholder\",[30,1,[\"searchPlaceholderMsg\"]]],[4,[38,4],[\"input\",[30,0,[\"updateGlobalFilterString\"]]],null]],[[\"@type\",\"@value\",\"@enter\"],[\"text\",[30,2],[30,0,[\"noop\"]]]],null],[1,\"\\n        \"],[10,1],[14,0,\"input-group-btn\"],[12],[1,\"\\n          \"],[11,\"button\"],[16,0,[29,[\"clearFilterIcon \",[30,1,[\"buttonDefaultSmall\"]]]]],[16,\"disabled\",[52,[51,[30,3]],\"disabled\"]],[24,4,\"button\"],[4,[38,4],[\"click\",[30,0,[\"clearGlobalFilter\"]]],null],[12],[1,\"\\n            \"],[10,\"i\"],[15,0,[30,1,[\"clearFilterIcon\"]]],[12],[13],[1,\"\\n            \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,1,[\"clearGlobalFilterMsg\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@themeInstance\",\"@value\",\"@globalFilterUsed\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"input\",\"on\",\"unless\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/bootstrap4/global-filter.hbs",
    "isStrictMode": false
  });
  class GlobalFilter extends _globalFilter.default {}
  _exports.default = GlobalFilter;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GlobalFilter);
});