define("ember-models-table/components/models-table/themes/ember-paper/expand-toggle", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/expand-toggle", "ember-models-table/utils/emt/themes/ep"], function (_exports, _component, _templateFactory, _util, _expandToggle, _ep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @isExpanded}}
    <this.PaperButton @onClick={{fn this.doCollapseRow @index @record}} @iconButton={{true}}>
      <this.PaperIcon @icon={{@themeInstance.collapseRowIcon}} class={{@themeInstance.collapseRowIcon}}/>
    </this.PaperButton>
  {{else}}
    <this.PaperButton @onClick={{fn this.doExpandRow @index @record}} @iconButton={{true}}>
      <this.PaperIcon @icon={{@themeInstance.expandRowIcon}} class={{@themeInstance.expandRowIcon}}/>
    </this.PaperButton>
  {{/if}}
  {{yield}}
  */
  {
    "id": "zbHReyNu",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[30,0,[\"PaperButton\"]],null,[[\"@onClick\",\"@iconButton\"],[[28,[37,1],[[30,0,[\"doCollapseRow\"]],[30,2],[30,3]],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,0,[\"PaperIcon\"]],[[16,0,[30,4,[\"collapseRowIcon\"]]]],[[\"@icon\"],[[30,4,[\"collapseRowIcon\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[30,0,[\"PaperButton\"]],null,[[\"@onClick\",\"@iconButton\"],[[28,[37,1],[[30,0,[\"doExpandRow\"]],[30,2],[30,3]],null],true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[30,0,[\"PaperIcon\"]],[[16,0,[30,4,[\"expandRowIcon\"]]]],[[\"@icon\"],[[30,4,[\"expandRowIcon\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]]],[18,5,null]],[\"@isExpanded\",\"@index\",\"@record\",\"@themeInstance\",\"&default\"],false,[\"if\",\"fn\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/expand-toggle.hbs",
    "isStrictMode": false
  });
  class ExpandToggle extends _expandToggle.default {
    get PaperButton() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperButton)(), this);
    }
    get PaperIcon() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperIcon)(), this);
    }
  }
  _exports.default = ExpandToggle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExpandToggle);
});