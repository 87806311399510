define("ember-models-table/components/models-table/themes/default/row-select-checkbox", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    class={{@themeInstance.buttonLink}}
    type="button"
    {{on "click" (fn this.doClickOnRow @index @record)}}>
    <i class={{if @isSelected @themeInstance.selectRowIcon @themeInstance.deselectRowIcon}}></i>
  </button>
  {{yield}}
  */
  {
    "id": "da8x9b/5",
    "block": "[[[11,\"button\"],[16,0,[30,1,[\"buttonLink\"]]],[24,4,\"button\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"doClickOnRow\"]],[30,2],[30,3]],null]],null],[12],[1,\"\\n  \"],[10,\"i\"],[15,0,[52,[30,4],[30,1,[\"selectRowIcon\"]],[30,1,[\"deselectRowIcon\"]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,5,null]],[\"@themeInstance\",\"@index\",\"@record\",\"@isSelected\",\"&default\"],false,[\"on\",\"fn\",\"if\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/row-select-checkbox.hbs",
    "isStrictMode": false
  });
  /**
   * Component with checkbox to select or deselect any single row
   *
   * Usage example:
   *
   * ```js
   * const columns = [
   *   {
   *     component: 'models-table/row-select-checkbox',
   *     disableFiltering: true,
   *     mayBeHidden: false,
   *     componentForSortCell: 'models-table/row-select-all-checkbox'
   *   },
   *   {propertyName: 'firstName'},
   *   {propertyName: 'lastName'}
   * ];
   * const data = [ ... ];
   * ```
   *
   * ```html
   * <ModelsTable
   *   @data={{this.data}}
   *   @columns={{this.columns}}
   *   @multipleSelect={{true}}
   * />
   * ```
   */
  let RowSelectCheckbox = (_class = class RowSelectCheckbox extends _component2.default {
    /**
     * @event doClickOnRow
     */
    doClickOnRow(index, record, e) {
      this.args.clickOnRow(index, record);
      e?.stopPropagation?.();
      return false;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "doClickOnRow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doClickOnRow"), _class.prototype)), _class);
  _exports.default = RowSelectCheckbox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RowSelectCheckbox);
});