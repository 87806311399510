define("ember-models-table/utils/emt/themes/ep", ["exports", "@embroider/macros/es-compat", "@embroider/macros/runtime", "@ember/debug"], function (_exports, _esCompat, _runtime, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPaperToolbar = _exports.getPaperTabs = _exports.getPaperSelect = _exports.getPaperMenu = _exports.getPaperInput = _exports.getPaperIcon = _exports.getPaperDivider = _exports.getPaperContent = _exports.getPaperCheckbox = _exports.getPaperCard = _exports.getPaperButton = void 0;
  const getPaperInput = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-paper not found', false));
    }
    return (0, _esCompat.default)(require("ember-paper/components/paper-input")).default;
  };
  _exports.getPaperInput = getPaperInput;
  const getPaperButton = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-paper not found', false));
    }
    return (0, _esCompat.default)(require("ember-paper/components/paper-button")).default;
  };
  _exports.getPaperButton = getPaperButton;
  const getPaperMenu = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-paper not found', false));
    }
    return (0, _esCompat.default)(require("ember-paper/components/paper-menu")).default;
  };
  _exports.getPaperMenu = getPaperMenu;
  const getPaperIcon = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-paper not found', false));
    }
    return (0, _esCompat.default)(require("ember-paper/components/paper-icon")).default;
  };
  _exports.getPaperIcon = getPaperIcon;
  const getPaperDivider = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-paper not found', false));
    }
    return (0, _esCompat.default)(require("ember-paper/components/paper-divider")).default;
  };
  _exports.getPaperDivider = getPaperDivider;
  const getPaperCheckbox = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-paper not found', false));
    }
    return (0, _esCompat.default)(require("ember-paper/components/paper-checkbox")).default;
  };
  _exports.getPaperCheckbox = getPaperCheckbox;
  const getPaperSelect = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-paper not found', false));
    }
    return (0, _esCompat.default)(require("ember-paper/components/paper-select")).default;
  };
  _exports.getPaperSelect = getPaperSelect;
  const getPaperTabs = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-paper not found', false));
    }
    return (0, _esCompat.default)(require("ember-paper/components/paper-tabs")).default;
  };
  _exports.getPaperTabs = getPaperTabs;
  const getPaperToolbar = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-paper not found', false));
    }
    return (0, _esCompat.default)(require("ember-paper/components/paper-toolbar")).default;
  };
  _exports.getPaperToolbar = getPaperToolbar;
  const getPaperCard = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-paper not found', false));
    }
    return (0, _esCompat.default)(require("ember-paper/components/paper-card")).default;
  };
  _exports.getPaperCard = getPaperCard;
  const getPaperContent = () => {
    if ((0, _runtime.macroCondition)(false)) {
      (true && !(false) && (0, _debug.assert)('ember-paper not found', false));
    }
    return (0, _esCompat.default)(require("ember-paper/components/paper-content")).default;
  };
  _exports.getPaperContent = getPaperContent;
});