define("@naeka/ember-custom-actions/actions/action", ["exports", "@ember/debug", "@ember/array", "@ember/string", "@ember/array/proxy", "@ember/object/proxy", "@ember/application", "@ember/utils", "@ember/object/promise-proxy-mixin", "ember-cached-decorator-polyfill", "@naeka/ember-custom-actions/utils/deep-merge", "@naeka/ember-custom-actions/utils/normalize-payload", "@naeka/ember-custom-actions/utils/url-builder"], function (_exports, _debug, _array, _string, _proxy, _proxy2, _application, _utils, _promiseProxyMixin, _emberCachedDecoratorPolyfill, _deepMerge, _normalizePayload, _urlBuilder) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const promiseProxiesMapping = {
    array: _proxy.default.extend(_promiseProxyMixin.default),
    object: _proxy2.default.extend(_promiseProxyMixin.default)
  };
  let Action = (_class = class Action {
    constructor(config) {
      (true && !(config.model) && (0, _debug.assert)('Custom actions require model property to be passed !', config.model));
      (true && !(!(config.instance && !config.model.id)) && (0, _debug.assert)('Custom action model has to be persisted !', !(config.instance && !config.model.id)));
      this.id = config.id ?? '';
      this.model = config.model;
      this.instance = config.instance ?? false;
      this.integrated = config.integrated ?? false;
      this.options = config.options ?? {};
    }

    /**
     * @private
     * @return {DS.Store}
     */
    get store() {
      return this.model.store;
    }

    /**
     * @private
     * @return {String}
     */
    get modelName() {
      let {
        constructor
      } = this.model;
      return constructor.modelName || constructor.typeKey;
    }

    /**
     * @private
     * @return {DS.Adapter}
     */
    get adapter() {
      return this.store.adapterFor(this.modelName);
    }

    /**
     * @private
     * @return {DS.Adapter}
     */
    get serializer() {
      return this.store.serializerFor(this.modelName);
    }

    /**
     * @private
     * @return {Object}
     */
    get config() {
      let appConfig = (0, _application.getOwner)(this.model).resolveRegistration('config:environment').emberCustomActions ?? {};
      return (0, _deepMerge.default)({}, appConfig, this.options);
    }

    /**
     * @public
     * @method callAction
     * @return {Promise}
     */
    callAction() {
      let promise = this._promise();
      let responseType = (0, _string.camelize)(this.config.responseType ?? '');
      let promiseProxy = promiseProxiesMapping[responseType];
      return promiseProxy ? promiseProxy.create({
        promise
      }) : promise;
    }

    /**
     * @private
     * @method queryParams
     * @return {Object}
     */
    queryParams() {
      let queryParams = this.config.queryParams;
      (true && !((0, _utils.typeOf)(queryParams) === 'object') && (0, _debug.assert)('Custom action queryParams option has to be an object', (0, _utils.typeOf)(queryParams) === 'object'));
      return this.adapter.sortQueryParams(queryParams);
    }

    /**
     * @private
     * @method requestMethod
     * @return {String}
     */
    requestMethod() {
      let integrated = this.integrated && this.adapter.methodForCustomAction;
      let method = this.config.method.toUpperCase();
      return integrated ? this._methodForCustomAction(method) : method;
    }

    /**
     * @private
     * @method requestUrl
     * @return {String}
     */
    requestUrl() {
      let integrated = this.integrated && this.adapter.urlForCustomAction;
      return integrated ? this._urlForCustomAction() : this._urlFromBuilder();
    }

    /**
     * @private
     * @method requestHeaders
     * @return {String}
     */
    requestHeaders() {
      let integrated = this.integrated && this.adapter.headersForCustomAction;
      let configHeaders = this.config.headers;
      let headers = integrated ? this._headersForCustomAction(configHeaders) : configHeaders;
      (true && !((0, _utils.typeOf)(headers) === 'object') && (0, _debug.assert)('Custom action headers option has to be an object', (0, _utils.typeOf)(headers) === 'object'));
      return headers;
    }

    /**
     * @private
     * @method requestData
     * @return {Object}
     */
    requestData() {
      let integrated = this.integrated && this.adapter.dataForCustomAction;
      let payload = this.config.data;
      let data = (integrated ? this._dataForCustomAction(payload) : payload) || {};
      (true && !((0, _utils.typeOf)(data) === 'object') && (0, _debug.assert)('Custom action payload has to be an object', (0, _utils.typeOf)(data) === 'object'));
      return (0, _normalizePayload.default)(data, this.config.normalizeOperation);
    }

    /**
     * @private
     * @method ajaxOptions
     * @return {Object}
     */
    ajaxOptions() {
      return (0, _deepMerge.default)({}, this.config.ajaxOptions, {
        data: this.requestData(),
        headers: this.requestHeaders()
      });
    }

    // Internals

    _promise() {
      return this.adapter.ajax(this.requestUrl(), this.requestMethod(), this.ajaxOptions()).then(this._onSuccess.bind(this), this._onError.bind(this));
    }
    _onSuccess(response) {
      if (this.config.pushToStore && this._validResponse(response)) {
        let store = this.store;
        let modelClass = this.model.constructor;
        let modelId = this.model.id;
        let actionId = this.id;
        let documentHash = this.serializer.normalizeArrayResponse(store, modelClass, response, modelId, actionId);
        return this.store.push(documentHash);
      }
      return response;
    }
    _onError(error) {
      if (this.config.pushToStore && (0, _array.isArray)(error.errors)) {
        let id = this.model.id;
        let typeClass = this.model.constructor;
        error.serializedErrors = this.serializer.extractErrors(this.store, typeClass, error, id);
      }
      return Promise.reject(error);
    }
    _validResponse(object) {
      return (0, _utils.typeOf)(object) === 'object' && Object.keys(object).length > 0;
    }
    _urlFromBuilder() {
      let path = this.id;
      let queryParams = this.queryParams();
      let modelName = this.modelName;
      let id = this.instance ? this.model.id : null;
      let url = this.adapter._buildURL(modelName, id);
      return (0, _urlBuilder.default)(url, path, queryParams);
    }

    // Adapter integration API

    _urlForCustomAction() {
      let id = this.model.id;
      let actionId = this.id;
      let queryParams = this.queryParams();
      let modelName = this.modelName;
      let adapterOptions = this.config.adapterOptions;
      let snapshot = this.model._internalModel.createSnapshot({
        adapterOptions
      });
      return this.adapter.urlForCustomAction(modelName, id, snapshot, actionId, queryParams);
    }
    _methodForCustomAction(method) {
      let actionId = this.id;
      let modelId = this.model.id;
      return this.adapter.methodForCustomAction({
        method,
        actionId,
        modelId
      });
    }
    _headersForCustomAction(headers) {
      let actionId = this.id;
      let modelId = this.model.id;
      return this.adapter.headersForCustomAction({
        headers,
        actionId,
        modelId
      });
    }
    _dataForCustomAction(data) {
      let actionId = this.id;
      let modelId = this.model.id;
      let model = this.model;
      return this.adapter.dataForCustomAction({
        data,
        actionId,
        modelId,
        model
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "store", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "store"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "modelName", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "modelName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "adapter", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "adapter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "serializer", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "serializer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "config", [_emberCachedDecoratorPolyfill.cached], Object.getOwnPropertyDescriptor(_class.prototype, "config"), _class.prototype)), _class);
  _exports.default = Action;
});