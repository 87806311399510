define("ember-models-table/components/models-table/themes/ember-bootstrap-v4/summary", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/summary", "ember-models-table/utils/emt/themes/ebs"], function (_exports, _component, _templateFactory, _util, _summary, _ebs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{@themeInstance.footerSummary}} {{this.paginationTypeClass}}">
    {{#if (has-block)}}
      {{yield
        (hash
          summary=this.summary
        )
      }}
    {{else}}
      <this.BsForm
        @formLayout="inline"
        @model={{this}}
        @onSubmit={{this.noop}}>
        <label>{{this.summary}}</label>
        <this.BsButton
          class="clearFilters border-0 {{@themeInstance.buttonLink}}"
          disabled={{unless @anyFilterUsed "disabled"}}
          @outline={{true}}
          @onClick={{this.doClearFilters}}>
          <i class={{@themeInstance.clearAllFiltersIcon}}></i>
          <span class="emt-sr-only">{{@themeInstance.clearAllFiltersMsg}}</span>
        </this.BsButton>
      </this.BsForm>
    {{/if}}
  </div>
  */
  {
    "id": "8w/8cBEQ",
    "block": "[[[10,0],[15,0,[29,[[30,1,[\"footerSummary\"]],\" \",[30,0,[\"paginationTypeClass\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[28,[37,3],null,[[\"summary\"],[[30,0,[\"summary\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[30,0,[\"BsForm\"]],null,[[\"@formLayout\",\"@model\",\"@onSubmit\"],[\"inline\",[30,0],[30,0,[\"noop\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,\"label\"],[12],[1,[30,0,[\"summary\"]]],[13],[1,\"\\n      \"],[8,[30,0,[\"BsButton\"]],[[16,0,[29,[\"clearFilters border-0 \",[30,1,[\"buttonLink\"]]]]],[16,\"disabled\",[52,[51,[30,2]],\"disabled\"]]],[[\"@outline\",\"@onClick\"],[true,[30,0,[\"doClearFilters\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[10,\"i\"],[15,0,[30,1,[\"clearAllFiltersIcon\"]]],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,1,[\"clearAllFiltersMsg\"]]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13]],[\"@themeInstance\",\"@anyFilterUsed\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"unless\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-bootstrap-v4/summary.hbs",
    "isStrictMode": false
  });
  class Summary extends _summary.default {
    get BsForm() {
      return (0, _util.ensureSafeComponent)((0, _ebs.getBsForm)(), this);
    }
    get BsButton() {
      return (0, _util.ensureSafeComponent)((0, _ebs.getBsButton)(), this);
    }
  }
  _exports.default = Summary;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Summary);
});