define("ember-models-table/components/models-table/themes/default/page-size-select", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{@themeInstance.pageSizeWrapper}}">
    {{#let
      (hash
        Select=(
          component (ensure-safe-component @themeInstance.selectComponent)
          id=this.inputId
          options=@pageSizeOptions
          value=@pageSize
          type=@type
          themeInstance=@themeInstance
          cssPropertyName="changePageSize"
          changeValue=@changePageSize
        )
      )
    as |PageSizeSelect|
    }}
      {{#if (has-block)}}
        {{yield PageSizeSelect}}
      {{else}}
        <form class={{@themeInstance.form}}>
          <div class={{@themeInstance.formElementWrapper}}>
            <label for={{this.inputId}}>{{@themeInstance.rowsCountMsg}}</label>
            <PageSizeSelect.Select/>
          </div>
        </form>
      {{/if}}
    {{/let}}
  </div>
  */
  {
    "id": "9wnpNlfE",
    "block": "[[[10,0],[15,0,[29,[[30,1,[\"pageSizeWrapper\"]]]]],[12],[1,\"\\n\"],[44,[[28,[37,1],null,[[\"Select\"],[[50,[28,[37,3],[[30,1,[\"selectComponent\"]]],null],0,null,[[\"id\",\"options\",\"value\",\"type\",\"themeInstance\",\"cssPropertyName\",\"changeValue\"],[[30,0,[\"inputId\"]],[30,2],[30,3],[30,4],[30,1],\"changePageSize\",[30,5]]]]]]]],[[[41,[48,[30,7]],[[[1,\"      \"],[18,7,[[30,6]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"form\"],[15,0,[30,1,[\"form\"]]],[12],[1,\"\\n        \"],[10,0],[15,0,[30,1,[\"formElementWrapper\"]]],[12],[1,\"\\n          \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,[30,1,[\"rowsCountMsg\"]]],[13],[1,\"\\n          \"],[8,[30,6,[\"Select\"]],null,null,null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]]]],[6]]],[13]],[\"@themeInstance\",\"@pageSizeOptions\",\"@pageSize\",\"@type\",\"@changePageSize\",\"PageSizeSelect\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"ensure-safe-component\",\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/page-size-select.hbs",
    "isStrictMode": false
  });
  /**
   * Dropdown with page size values used within {@link DefaultTheme.TableFooter | TableFooter}.
   *
   * Value from {@link Core.ModelsTable.pageSizeOptions | ModelsTable.pageSizeOptions} is used as a list of dropdown-options.
   *
   * Usage example:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PageSizeSelect />
   *     {{! ... }}
   *   </MT.Footer>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   * Block usage example:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Footer as |Footer|>
   *     <Footer.PageSizeSelect as |SizeSelectBlock|>
   *       <SizeSelectBlock.Select />
   *     </Footer.PageSizeSelect>
   *   </MT.Footer>
   * </ModelsTable>
   * ```
   * References to the following contextual components are yielded:
   *
   * * {@link DefaultTheme.Select | Select} - selectbox with list of available page size options
   */
  class PageSizeSelect extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "elementId", (0, _internals.guidFor)(this));
    }
    get inputId() {
      return `${this.elementId}-page-size-select`;
    }
  }
  _exports.default = PageSizeSelect;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PageSizeSelect);
});