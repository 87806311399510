define("ember-in-viewport/mixins/in-viewport", ["exports", "@ember/polyfills", "@ember/object/mixin", "@ember/utils", "@ember/debug", "@ember/service", "@ember/object", "@ember/runloop", "@ember/object/computed", "@ember/application", "ember-in-viewport/-private/raf-admin", "ember-in-viewport/utils/can-use-dom", "ember-in-viewport/utils/can-use-raf", "ember-in-viewport/utils/find-elem", "ember-in-viewport/utils/can-use-intersection-observer", "ember-in-viewport/utils/check-scroll-direction"], function (_exports, _polyfills, _mixin, _utils, _debug, _service, _object, _runloop, _computed, _application, _rafAdmin, _canUseDom, _canUseRaf, _findElem, _canUseIntersectionObserver, _checkScrollDirection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/no-new-mixins, ember/no-get, ember/no-observers, ember/no-incorrect-calls-with-inline-anonymous-functions */

  const lastDirection = {};
  const lastPosition = {};
  var _default = _mixin.default.create({
    /**
     * @property _debouncedEventHandler
     * @default null
     */
    _debouncedEventHandler: null,
    /**
     * unbinding listeners will short circuit rAF
     *
     * @property _stopListening
     * @default false
     */
    _stopListening: false,
    inViewport: (0, _service.inject)(),
    /**
     * @property viewportExited
     * @type Boolean
     */
    viewportExited: (0, _computed.not)('viewportEntered').readOnly(),
    init() {
      // ensure this mixin runs first, then your component can override the options
      this._super(...arguments);
      let options = (0, _polyfills.assign)({
        viewportUseRAF: (0, _canUseRaf.default)(),
        viewportEntered: false,
        viewportListeners: []
      }, this._buildOptions());

      // set viewportUseIntersectionObserver after merging users config to avoid errors in browsers that lack support (https://github.com/DockYard/ember-in-viewport/issues/146)
      options = (0, _polyfills.assign)(options, {
        viewportUseIntersectionObserver: (0, _canUseIntersectionObserver.default)()
      });
      (0, _object.setProperties)(this, options);
      (0, _object.set)(this, '_evtListenerClosures', []);
    },
    didInsertElement() {
      this._super(...arguments);
      (true && !(false) && (0, _debug.deprecate)('This mixin is deprecated. We suggest you migrate to the inViewport service or use the {{in-viewport}} modifier', false, {
        id: 'ember-in-viewport.mixin',
        until: '4.0.0',
        url: 'https://github.com/DockYard/ember-in-viewport#readme'
      }));
      if (!_canUseDom.default) {
        return;
      }
      const viewportEnabled = (0, _object.get)(this, 'viewportEnabled');
      if (viewportEnabled) {
        this.watchElement((0, _object.get)(this, 'element'));
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      this._unbindListeners((0, _object.get)(this, 'element'));
    },
    _buildOptions() {
      let defaultOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      const owner = (0, _application.getOwner)(this);
      if (owner) {
        return (0, _polyfills.assign)(defaultOptions, owner.lookup('config:in-viewport'));
      }
    },
    watchElement(element) {
      this._setInitialViewport(element);
      this._addObserverIfNotSpying(element);
      const viewportDidScroll = (0, _object.get)(this, 'viewportDidScroll');
      if (viewportDidScroll) {
        (0, _debug.debug)('[viewportDidScroll] This will be false by default in the next major release');
        this._bindScrollDirectionListener((0, _object.get)(this, 'viewportScrollSensitivity'));
      }
      if (!(0, _object.get)(this, 'viewportUseIntersectionObserver') && !(0, _object.get)(this, 'viewportUseRAF')) {
        (0, _object.get)(this, 'viewportListeners').forEach(listener => {
          let {
            context,
            event
          } = listener;
          context = (0, _object.get)(this, 'scrollableArea') || context;
          this._bindListeners(context, event, element);
        });
      }
    },
    _addObserverIfNotSpying(element) {
      if (!(0, _object.get)(this, 'viewportSpy')) {
        this.addObserver('viewportEntered', this, (0, _runloop.bind)(this, '_unbindIfEntered', element));
      }
    },
    _setInitialViewport(element) {
      const isTearingDown = this.isDestroyed || this.isDestroying;
      if (!element || isTearingDown) {
        return;
      }
      const inViewport = (0, _object.get)(this, 'inViewport');
      if ((0, _object.get)(this, 'viewportUseIntersectionObserver')) {
        return (0, _runloop.scheduleOnce)('afterRender', this, () => {
          const scrollableArea = (0, _object.get)(this, 'scrollableArea');
          const viewportTolerance = (0, _object.get)(this, 'viewportTolerance');
          const intersectionThreshold = (0, _object.get)(this, 'intersectionThreshold');
          inViewport.watchElement(element, {
            intersectionThreshold,
            viewportTolerance,
            scrollableArea
          }, (0, _runloop.bind)(this, this._onEnterIntersection), (0, _runloop.bind)(this, this._onExitIntersection));
        });
      } else if ((0, _object.get)(this, 'viewportUseRAF')) {
        inViewport.startRAF();
        const scrollableArea = (0, _object.get)(this, 'scrollableArea');
        const viewportTolerance = (0, _object.get)(this, 'viewportTolerance');
        const viewportSpy = (0, _object.get)(this, 'viewportSpy');
        const enterCallback = () => {
          const isTearingDown = this.isDestroyed || this.isDestroying;
          const viewportEntered = element.getAttribute('data-in-viewport-entered') === 'true';
          if (!isTearingDown && (viewportSpy || viewportEntered)) {
            (0, _object.set)(this, 'viewportEntered', true);
            this.trigger('didEnterViewport');
          }
        };
        const exitCallback = () => {
          const isTearingDown = this.isDestroyed || this.isDestroying;
          if (!isTearingDown && viewportSpy) {
            (0, _object.set)(this, 'viewportEntered', false);
            this.trigger('didExitViewport');
          }
        };
        (0, _rafAdmin.startRAF)(element, {
          scrollableArea,
          viewportTolerance,
          viewportSpy
        }, enterCallback, exitCallback, inViewport.addRAF.bind(inViewport, element.id), inViewport.removeRAF.bind(inViewport, element.id));
      } else {
        return (0, _runloop.scheduleOnce)('afterRender', this, () => {
          this._setViewportEntered(element);
        });
      }
    },
    /**
     * used by rAF and scroll event listeners to determine if mixin is in viewport
     * Remember to set `viewportSpy` to true if you want to continuously observe your element
     *
     * @method _setViewportEntered
     */
    _setViewportEntered(element) {
      const scrollableArea = (0, _object.get)(this, 'scrollableArea') ? document.querySelector((0, _object.get)(this, 'scrollableArea')) : undefined;
      const height = scrollableArea ? scrollableArea.offsetHeight + scrollableArea.getBoundingClientRect().top : window.innerHeight;
      const width = scrollableArea ? scrollableArea.offsetWidth + scrollableArea.getBoundingClientRect().left : window.innerWidth;
      const boundingClientRect = element.getBoundingClientRect();
      if (boundingClientRect) {
        this._triggerDidAccessViewport((0, _object.get)(this, 'inViewport').isInViewport(boundingClientRect, height, width, (0, _object.get)(this, 'viewportTolerance')), (0, _object.get)(this, 'viewportEntered'));
        if ((0, _object.get)(this, 'viewportUseRAF') && !(0, _object.get)(this, '_stopListening')) {
          (0, _object.get)(this, 'inViewport').addRAF((0, _object.get)(this, 'elementId'), (0, _runloop.bind)(this, this._setViewportEntered, element));
        }
      }
    },
    /**
     * Callback provided to IntersectionObserver
     * trigger didEnterViewport callback
     *
     * @method _onEnterIntersection
     */
    _onEnterIntersection() {
      const isTearingDown = this.isDestroyed || this.isDestroying;
      if (!isTearingDown) {
        (0, _object.set)(this, 'viewportEntered', true);
      }
      this.trigger('didEnterViewport');
    },
    /**
     * trigger didExitViewport callback
     *
     * @method _onExitIntersection
     */
    _onExitIntersection() {
      const isTearingDown = this.isDestroyed || this.isDestroying;
      if (!isTearingDown) {
        (0, _object.set)(this, 'viewportEntered', false);
      }
      this.trigger('didExitViewport');
    },
    /**
     * @method _triggerDidScrollDirection
     * @param contextEl
     * @param sensitivity
     */
    _triggerDidScrollDirection() {
      let contextEl = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let sensitivity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
      (true && !(contextEl) && (0, _debug.assert)('You must pass a valid context element to _triggerDidScrollDirection', contextEl));
      (true && !(sensitivity) && (0, _debug.assert)('sensitivity cannot be 0', sensitivity));
      const elementId = (0, _object.get)(this, 'elementId');
      const lastDirectionForEl = lastDirection[elementId];
      const lastPositionForEl = lastPosition[elementId];
      const newPosition = {
        top: contextEl.scrollTop,
        left: contextEl.scrollLeft
      };
      const scrollDirection = (0, _checkScrollDirection.default)(lastPositionForEl, newPosition, sensitivity);
      const directionChanged = scrollDirection !== lastDirectionForEl;
      if (scrollDirection && directionChanged && (0, _object.get)(this, 'viewportDidScroll')) {
        this.trigger('didScroll', scrollDirection);
        lastDirection[elementId] = scrollDirection;
      }
      lastPosition[elementId] = newPosition;
    },
    /**
     * @method _triggerDidAccessViewport
     * @param hasEnteredViewport
     * @param viewportEntered
     */
    _triggerDidAccessViewport() {
      let hasEnteredViewport = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      let viewportEntered = arguments.length > 1 ? arguments[1] : undefined;
      const isTearingDown = this.isDestroyed || this.isDestroying;
      if (isTearingDown) {
        return;
      }
      const didEnter = !viewportEntered && hasEnteredViewport;
      const didLeave = viewportEntered && !hasEnteredViewport;
      let triggeredEventName = '';
      if (didEnter) {
        triggeredEventName = 'didEnterViewport';
      }
      if (didLeave) {
        triggeredEventName = 'didExitViewport';
      }
      if ((0, _object.get)(this, 'viewportSpy') || !viewportEntered) {
        (0, _object.set)(this, 'viewportEntered', hasEnteredViewport);
      }
      if (triggeredEventName) {
        this.trigger(triggeredEventName);
      }
    },
    /**
     * Unbind when enter viewport only if viewportSpy is false
     *
     * @method _unbindIfEntered
     */
    _unbindIfEntered(element) {
      if ((0, _object.get)(this, 'viewportEntered')) {
        this._unbindListeners(element);
        this.removeObserver('viewportEntered', this, '_unbindIfEntered');
        (0, _object.set)(this, 'viewportEntered', false);
      }
    },
    /**
     * General utility function
     *
     * @method _debouncedEvent
     */
    _debouncedEvent(methodName) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }
      (true && !(methodName) && (0, _debug.assert)('You must pass a methodName to _debouncedEvent', methodName));
      (true && !((0, _utils.typeOf)(methodName) === 'string') && (0, _debug.assert)('methodName must be a string', (0, _utils.typeOf)(methodName) === 'string'));
      (0, _runloop.debounce)(this, () => this[methodName](...args), (0, _object.get)(this, 'viewportRefreshRate'));
    },
    _bindScrollDirectionListener() {
      let sensitivity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
      (true && !(sensitivity) && (0, _debug.assert)('sensitivity cannot be 0', sensitivity));
      const contextEl = (0, _object.get)(this, 'scrollableArea') || window;
      const elem = (0, _findElem.default)(contextEl);
      this._debouncedEventHandler = this._debouncedEvent.bind(this, '_triggerDidScrollDirection', elem, sensitivity);
      elem.addEventListener('scroll', this._debouncedEventHandler, {
        passive: true
      });
    },
    _unbindScrollDirectionListener() {
      const elementId = (0, _object.get)(this, 'elementId');
      const context = (0, _object.get)(this, 'scrollableArea') || window;
      const elem = (0, _findElem.default)(context);
      if (elem) {
        elem.removeEventListener('scroll', this._debouncedEventHandler, {
          passive: true
        });
        delete lastPosition[elementId];
        delete lastDirection[elementId];
      }
    },
    /**
     * Only if not using IntersectionObserver and rAF
     *
     * @method _bindListeners
     */
    _bindListeners() {
      let context = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      let event = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      let element = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      (true && !(context) && (0, _debug.assert)('You must pass a valid context to _bindListeners', context));
      (true && !(event) && (0, _debug.assert)('You must pass a valid event to _bindListeners', event));
      let elem = (0, _findElem.default)(context);
      let evtListener = () => this._debouncedEvent('_setViewportEntered', element);
      this._evtListenerClosures.push({
        event: event,
        evtListener
      });
      elem.addEventListener(event, evtListener, false);
    },
    /**
     * Remove listeners for rAF or scroll event listeners
     * Either from component destroy or viewport entered and
     * need to turn off listening
     *
     * @method _unbindListeners
     */
    _unbindListeners(element) {
      (0, _object.set)(this, '_stopListening', true);

      // if IntersectionObserver
      if ((0, _object.get)(this, 'viewportUseIntersectionObserver') && (0, _object.get)(this, 'viewportEnabled')) {
        (0, _object.get)(this, 'inViewport').unobserveIntersectionObserver(element);
      }

      // if rAF
      if (!(0, _object.get)(this, 'viewportUseIntersectionObserver') && (0, _object.get)(this, 'viewportUseRAF')) {
        const elementId = (0, _object.get)(this, 'elementId');
        (0, _object.get)(this, 'inViewport').removeRAF(elementId);
      }

      // if scroll event listeners
      if (!(0, _object.get)(this, 'viewportUseIntersectionObserver') && !(0, _object.get)(this, 'viewportUseRAF')) {
        (0, _object.get)(this, 'viewportListeners').forEach(listener => {
          let {
            context,
            event
          } = listener;
          context = (0, _object.get)(this, 'scrollableArea') || context;
          let elem = (0, _findElem.default)(context);
          let {
            evtListener
          } = this._evtListenerClosures.find(closure => event === closure.event) || {};
          elem.removeEventListener(event, evtListener, false);
        });
      }

      // 4. last but not least
      const viewportDidScroll = (0, _object.get)(this, 'viewportDidScroll');
      if (viewportDidScroll) {
        this._unbindScrollDirectionListener();
      }
    }
  });
  _exports.default = _default;
});