define("@naeka/ember-custom-actions/index", ["exports", "@naeka/ember-custom-actions/actions/resource", "@naeka/ember-custom-actions/actions/model", "@naeka/ember-custom-actions/actions/custom", "@naeka/ember-custom-actions/decorators/adapter"], function (_exports, _resource, _model, _custom, _adapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "customAction", {
    enumerable: true,
    get: function () {
      return _custom.default;
    }
  });
  Object.defineProperty(_exports, "modelAction", {
    enumerable: true,
    get: function () {
      return _model.default;
    }
  });
  Object.defineProperty(_exports, "resourceAction", {
    enumerable: true,
    get: function () {
      return _resource.default;
    }
  });
  Object.defineProperty(_exports, "withCustomActions", {
    enumerable: true,
    get: function () {
      return _adapter.default;
    }
  });
});