define("ember-models-table/utils/emt/better-compare", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = betterCompare;
  /*
   * This is a better version of Ember.compare.
   * Sadly, Ember.compare() will always return 0 when comparing two instances of JavaScript objects that do not
   * implement the Comparable-mixin.
   * This function will compare instances via their `valueOf()` method if available.
   */
  function betterCompare(v, w) {
    // eslint-disable-line
    if (!v || !w) {
      return (0, _utils.compare)(v, w);
    }
    if (typeof v.compare === 'function' && typeof w.compare === 'function') {
      return v.compare(v, w);
    }
    if (typeof v.valueOf === 'function' && typeof w.valueOf === 'function') {
      return (0, _utils.compare)(v.valueOf(), w.valueOf());
    }
    return (0, _utils.compare)(v, w);
  }
});