define("ember-models-table/components/models-table/themes/default/grouped-header", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
    {{#if (has-block)}}
      {{yield (hash groupedHeader=@groupedHeader shouldAddExtraColumn=this.shouldAddExtraColumn)}}
    {{else}}
      {{#if this.shouldAddExtraColumn}}
        <th></th>
      {{/if}}
      {{#each @groupedHeader as |cell|}}
        <th colspan={{cell.colspan}} rowspan={{cell.rowspan}}>{{cell.title}}</th>
      {{/each}}
    {{/if}}
  </tr>
  */
  {
    "id": "/wrPz8ne",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[28,[37,3],null,[[\"groupedHeader\",\"shouldAddExtraColumn\"],[[30,1],[30,0,[\"shouldAddExtraColumn\"]]]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"shouldAddExtraColumn\"]],[[[1,\"      \"],[10,\"th\"],[12],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,5],[[28,[37,5],[[30,1]],null]],null],null,[[[1,\"      \"],[10,\"th\"],[15,\"colspan\",[30,2,[\"colspan\"]]],[15,\"rowspan\",[30,2,[\"rowspan\"]]],[12],[1,[30,2,[\"title\"]]],[13],[1,\"\\n\"]],[2]],null]],[]]],[13]],[\"@groupedHeader\",\"cell\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"each\",\"-track-array\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/grouped-header.hbs",
    "isStrictMode": false
  });
  /**
   * Table header item used within {@link DefaultTheme.TableHeader | TableHeader}.
   *
   * Each `grouped-header` should represent one item from {@link Core.ModelsTable.groupedHeaders | ModelsTable.groupedHeaders}.
   *
   * Usage example:
   *
   * ```js
   * const groupedHeaders = [
   *   [{title: 'BigTitle', colspan: 5}],
   *   [{title: 'SubTitle1', colspan: 2}, {title: 'SubTitle2', colspan: 3}]
   * ];
   * ```
   *
   * ```html
   * <ModelsTable
   *   @columns={{this.columns}}
   *   @data={{this.data}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       {{#each groupedHeaders as |groupedHeader|}}
   *         <Header.GroupedHeader @groupedHeader={{groupedHeader}} as |GroupedHeader|>
   *           {{#each GroupedHeader.groupedHeader as |cell|}}
   *             <th colspan={{cell.colspan}} rowspan={{cell.rowspan}}>{{cell.title}}</th>
   *           {{/each}}
   *         </Header.GroupedHeader>
   *       {{/each}}
   *     </Table.Header>
   *   </MT.Table>
   * </ModelsTable>
   * ```
   */
  class GroupedHeader extends _component2.default {
    /**
     * @default false
     */
    get shouldAddExtraColumn() {
      return this.args.displayGroupedValueAs === 'column' && this.args.useDataGrouping && !!this.args.visibleProcessedColumns.length;
    }
  }
  _exports.default = GroupedHeader;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GroupedHeader);
});