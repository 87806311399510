define("ember-models-table/services/emt-themes/ember-bootstrap-v5", ["exports", "@glimmer/tracking", "@embroider/util", "ember-models-table/services/emt-themes/default", "ember-models-table/components/models-table/themes/ember-bootstrap-v5/columns-dropdown", "ember-models-table/components/models-table/themes/ember-bootstrap-v5/data-group-by-select", "ember-models-table/components/models-table/themes/ember-bootstrap-v5/global-filter", "ember-models-table/components/models-table/themes/ember-bootstrap-v5/row-filtering-cell", "ember-models-table/components/models-table/themes/ember-bootstrap-v5/summary", "ember-models-table/components/models-table/themes/ember-bootstrap-v5/page-size-select", "ember-models-table/components/models-table/themes/ember-bootstrap-v5/pagination-simple", "ember-models-table/components/models-table/themes/ember-bootstrap-v5/pagination-numeric"], function (_exports, _tracking, _util, _default, _columnsDropdown, _dataGroupBySelect, _globalFilter, _rowFilteringCell, _summary, _pageSizeSelect, _paginationSimple, _paginationNumeric) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  let EmberBootstrap5Theme = (_class = class EmberBootstrap5Theme extends _default.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "table", 'table table-striped table-bordered table-condensed table-sm table-hover');
      _defineProperty(this, "globalFilterWrapper", 'pull-left');
      _defineProperty(this, "columnsDropdown", 'dropdown-menu dropdown-menu-right');
      _defineProperty(this, "columnsDropdownWrapper", 'pull-right columns-dropdown dropdown');
      _defineProperty(this, "columnsDropdownDivider", 'dropdown-divider');
      _defineProperty(this, "buttonLink", 'btn btn-link');
      _initializerDefineProperty(this, "buttonDefault", _descriptor, this);
      _defineProperty(this, "footerSummaryNumericPagination", 'col-4');
      _defineProperty(this, "footerSummaryDefaultPagination", 'col-5');
      _defineProperty(this, "pageSizeWrapper", 'col-2');
      _defineProperty(this, "pageSizeSelectWrapper", 'pull-right');
      _defineProperty(this, "paginationBlock", 'btn-group pull-right');
      _defineProperty(this, "paginationWrapperNumeric", 'col-6');
      _defineProperty(this, "paginationWrapperDefault", 'col-5');
      _defineProperty(this, "clearFilterIcon", 'fa fa-fw fa-times form-control-feedback');
      _defineProperty(this, "clearAllFiltersIcon", 'fa fa-fw fa-times');
      _defineProperty(this, "sortGroupedPropertyBtn", 'btn');
      _defineProperty(this, "tfooterInternalWrapper", 'row');
      _defineProperty(this, "input", 'form-control');
      _defineProperty(this, "inputGroup", 'input-group');
      _defineProperty(this, "form", 'form-inline');
      _defineProperty(this, "formElementWrapper", 'form-group');
      _defineProperty(this, "paginationNumericItemActive", 'active');
      _defineProperty(this, "sortAscIcon", 'fa fa-fw fa-sort-asc');
      _defineProperty(this, "sortDescIcon", 'fa fa-fw fa-sort-desc');
      _defineProperty(this, "columnVisibleIcon", 'fa fa-fw fa-check-square-o');
      _defineProperty(this, "columnHiddenIcon", 'fa fa-fw fa-square-o');
      _defineProperty(this, "navFirstIcon", 'fa fa-fw fa-angle-double-left');
      _defineProperty(this, "navPrevIcon", 'fa fa-fw fa-angle-left');
      _defineProperty(this, "navNextIcon", 'fa fa-fw fa-angle-right');
      _defineProperty(this, "navLastIcon", 'fa fa-fw fa-angle-double-right');
      _defineProperty(this, "caretIcon", 'caret');
      _defineProperty(this, "expandRowIcon", 'fa fa-fw fa-plus');
      _defineProperty(this, "expandAllRowsIcon", 'fa fa-fw fa-plus');
      _defineProperty(this, "collapseRowIcon", 'fa fa-fw fa-minus');
      _defineProperty(this, "collapseAllRowsIcon", 'fa fa-fw fa-minus');
      _defineProperty(this, "selectAllRowsIcon", 'fa fa-fw fa-check-square-o');
      _defineProperty(this, "deselectAllRowsIcon", 'fa fa-fw fa-square-o');
      _defineProperty(this, "selectSomeRowsIcon", 'fa fa-fw fa-minus-square-o');
      _defineProperty(this, "selectRowIcon", 'fa fa-fw fa-check-square-o');
      _defineProperty(this, "deselectRowIcon", 'fa fa-fw fa-square-o');
    }
    /**
     * @default 'models-table/themes/ember-bootstrap-v5/columns-dropdown'
     */
    get columnsDropdownComponent() {
      return (0, _util.ensureSafeComponent)(_columnsDropdown.default, this);
    }

    /**
     * @default 'models-table/themes/ember-bootstrap-v5/data-group-by-select'
     */
    get dataGroupBySelectComponent() {
      return (0, _util.ensureSafeComponent)(_dataGroupBySelect.default, this);
    }

    /**
     * @default 'models-table/themes/ember-bootstrap-v5/global-filter'
     */
    get globalFilterComponent() {
      return (0, _util.ensureSafeComponent)(_globalFilter.default, this);
    }

    /**
     * @default 'models-table/themes/ember-bootstrap-v5/row-filtering-cell'
     */
    get rowFilteringCellComponent() {
      return (0, _util.ensureSafeComponent)(_rowFilteringCell.default, this);
    }

    /**
     * @default 'models-table/themes/ember-bootstrap-v5/summary'
     */
    get summaryComponent() {
      return (0, _util.ensureSafeComponent)(_summary.default, this);
    }

    /**
     * @default 'models-table/themes/ember-bootstrap-v5/page-size-select'
     */
    get pageSizeSelectComponent() {
      return (0, _util.ensureSafeComponent)(_pageSizeSelect.default, this);
    }

    /**
     * @default 'models-table/themes/ember-bootstrap-v5/pagination-simple'
     */
    get paginationSimpleComponent() {
      return (0, _util.ensureSafeComponent)(_paginationSimple.default, this);
    }

    /**
     * @default 'models-table/themes/ember-bootstrap-v5/pagination-numeric'
     */
    get paginationNumericComponent() {
      return (0, _util.ensureSafeComponent)(_paginationNumeric.default, this);
    }
    get buttonDefaultSmall() {
      return `${this.buttonDefault} btn-sm`;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "buttonDefault", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'btn btn-secondary';
    }
  })), _class);
  _exports.default = EmberBootstrap5Theme;
});