define("ember-models-table/components/models-table/themes/default/expand-all-toggle", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    class="{{@themeInstance.buttonLink}} {{@themeInstance.expandAllRows}}"
    type="button"
    {{on "click" this.doExpandAllRows}}>
    <i class={{@themeInstance.expandAllRowsIcon}}></i>
  </button>
  <br>
  <button
    class="{{@themeInstance.buttonLink}} {{@themeInstance.collapseAllRows}}"
    type="button"
    {{on "click" this.doCollapseAllRows}}>
    <i class={{@themeInstance.collapseAllRowsIcon}}></i>
  </button>
  {{yield}}
  */
  {
    "id": "rJ3uz7Bj",
    "block": "[[[11,\"button\"],[16,0,[29,[[30,1,[\"buttonLink\"]],\" \",[30,1,[\"expandAllRows\"]]]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"doExpandAllRows\"]]],null],[12],[1,\"\\n  \"],[10,\"i\"],[15,0,[30,1,[\"expandAllRowsIcon\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"br\"],[12],[13],[1,\"\\n\"],[11,\"button\"],[16,0,[29,[[30,1,[\"buttonLink\"]],\" \",[30,1,[\"collapseAllRows\"]]]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"doCollapseAllRows\"]]],null],[12],[1,\"\\n  \"],[10,\"i\"],[15,0,[30,1,[\"collapseAllRowsIcon\"]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,2,null]],[\"@themeInstance\",\"&default\"],false,[\"on\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/expand-all-toggle.hbs",
    "isStrictMode": false
  });
  /**
   * Component to expand or collapse all rows
   *
   * Usage example:
   *
   * ```js
   * const columns = [
   *   {
   *     component: 'models-table/themes/default/expand-toggle',
   *     componentForFilterCell: 'models-table/expand-all-toggle',
   *     mayBeHidden: false
   *   },
   *   {propertyName: 'firstName'},
   *   {propertyName: 'lastName'}
   * ];
   *
   * const data = [ ... ];
   * ```
   *
   * ```html
   * <ModelsTable
   *   @data={{this.data}}
   *   @columns={{this.columns}}
   *   @multipleExpand={{true}}
   * />
   * ```
   */
  let ExpandAllToggle = (_class = class ExpandAllToggle extends _component2.default {
    /**
     * @event doCollapseAllRows
     */
    doCollapseAllRows() {
      this.args.collapseAllRows();
      return false;
    }

    /**
     * @event doExpandAllRows
     */
    doExpandAllRows() {
      this.args.expandAllRows();
      return false;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "doCollapseAllRows", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doCollapseAllRows"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doExpandAllRows", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doExpandAllRows"), _class.prototype)), _class);
  _exports.default = ExpandAllToggle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExpandAllToggle);
});