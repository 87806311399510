define("ember-models-table/components/models-table/themes/default/table-footer", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tfoot>
    {{#let
      (hash
        shouldAddExtraColumn=this.shouldAddExtraColumn
      )
    as |TableFooter|
    }}
      {{#if (has-block)}}
        {{yield TableFooter}}
      {{else}}
        <tr>
          {{#if this.shouldAddExtraColumn}}
            <td></td>
          {{/if}}
          {{#each @visibleProcessedColumns as |column|}}
            {{#if column.componentForFooterCell}}
              {{component
                (ensure-safe-component column.componentForFooterCell)
                selectedItems=@selectedItems
                expandedItems=@expandedItems
                data=@data
                mappedSelectedItems=(if column.propertyName (map-by column.propertyName @selectedItems))
                mappedExpandedItems=(if column.propertyName (map-by column.propertyName @expandedItems))
                mappedData=(if column.propertyName (map-by column.propertyName @data))
              }}
            {{else}}
              <td></td>
            {{/if}}
          {{/each}}
        </tr>
      {{/if}}
    {{/let}}
  </tfoot>
  */
  {
    "id": "YRkaN0gy",
    "block": "[[[10,\"tfoot\"],[12],[1,\"\\n\"],[44,[[28,[37,1],null,[[\"shouldAddExtraColumn\"],[[30,0,[\"shouldAddExtraColumn\"]]]]]],[[[41,[48,[30,7]],[[[1,\"      \"],[18,7,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[10,\"tr\"],[12],[1,\"\\n\"],[41,[30,0,[\"shouldAddExtraColumn\"]],[[[1,\"          \"],[10,\"td\"],[12],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,6],[[28,[37,6],[[30,2]],null]],null],null,[[[41,[30,3,[\"componentForFooterCell\"]],[[[1,\"            \"],[46,[28,[37,8],[[30,3,[\"componentForFooterCell\"]]],null],null,[[\"selectedItems\",\"expandedItems\",\"data\",\"mappedSelectedItems\",\"mappedExpandedItems\",\"mappedData\"],[[30,4],[30,5],[30,6],[52,[30,3,[\"propertyName\"]],[28,[37,9],[[30,3,[\"propertyName\"]],[30,4]],null]],[52,[30,3,[\"propertyName\"]],[28,[37,9],[[30,3,[\"propertyName\"]],[30,5]],null]],[52,[30,3,[\"propertyName\"]],[28,[37,9],[[30,3,[\"propertyName\"]],[30,6]],null]]]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,\"td\"],[12],[13],[1,\"\\n\"]],[]]]],[3]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]]]],[1]]],[13]],[\"TableFooter\",\"@visibleProcessedColumns\",\"column\",\"@selectedItems\",\"@expandedItems\",\"@data\",\"&default\"],false,[\"let\",\"hash\",\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\",\"component\",\"ensure-safe-component\",\"map-by\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/table-footer.hbs",
    "isStrictMode": false
  });
  /**
   * Table footer used within {@link DefaultTheme.Table}.
   *
   * By default it's an empty component. It should be used with a block context:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Footer as |Footer|>
   *       {{! ... }}
   *     </Table.Footer>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example 2:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Footer as |Footer|>
   *        <tr>
   *          <td colspan={{if Footer.shouldAddExtraColumn  (inc MT.visibleProcessedColumns.length) MT.visibleProcessedColumns.length}}>
   *            {{! "inc" is a helper from `ember-composable-helpers` }}
   *            Some custom summary for table can be shown in the <code>tfoot</code>
   *          </td>
   *        </tr>
   *      </Table.Footer>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * References to the following properties are yielded:
   *
   * * {@link shouldAddExtraColumn} - determines if extra column should be added to the row in the `tfoot`. It happens when rows grouping is used and extra column with group values exists
   */
  class TableFooter extends _component2.default {
    /**
     * @default false
     */
    get shouldAddExtraColumn() {
      return this.args.displayGroupedValueAs === 'column' && this.args.useDataGrouping && !!this.args.visibleProcessedColumns.length;
    }
  }
  _exports.default = TableFooter;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TableFooter);
});