define("@naeka/ember-custom-actions/utils/normalize-payload", ["exports", "@ember/debug", "@ember/array", "@ember/string"], function (_exports, _debug, _array, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const transformFunctions = {
    camelize: _string.camelize,
    capitalize: _string.capitalize,
    classify: _string.classify,
    dasherize: _string.dasherize,
    decamelize: _string.decamelize,
    underscore: _string.underscore
  };
  function transformObject(object, operation) {
    if (object instanceof Object && !(0, _array.isArray)(object)) {
      let data = {};
      Object.keys(object).forEach(key => {
        let transform = transformFunctions[operation];
        data[transform(key)] = transformObject(object[key], operation);
      });
      return data;
    } else {
      return object;
    }
  }
  function _default(payload, operation) {
    if (operation) {
      (true && !(!!transformFunctions[operation]) && (0, _debug.assert)("This normalize method of custom action's payload does not exist. Check Ember.String documentation!", !!transformFunctions[operation]));
      return transformObject(payload, operation);
    } else {
      return payload;
    }
  }
});