define("ember-models-table/components/models-table/themes/default/cell-edit-toggle", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "rsvp"], function (_exports, _component, _templateFactory, _component2, _object, _rsvp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-invalid-interactive  --}}
  <div {{on "click" this.onClick}}>
    {{#if @isEditRow}}
      <button
        class="{{@themeInstance.buttonDefault}} {{@themeInstance.cancelRowButton}}"
        type="button"
        {{on "click" this.cancelClicked}}>
        {{@themeInstance.cancelRowButtonLabelMsg}}
      </button>
      <button
        class="{{@themeInstance.buttonDefault}} {{@themeInstance.saveRowButton}}"
        type="button"
        {{on "click" this.saveClicked}}>
        {{@themeInstance.saveRowButtonLabelMsg}}
      </button>
    {{else}}
      <button
        class="{{@themeInstance.buttonDefault}} {{@themeInstance.editRowButton}}"
        type="button"
        {{on "click" this.editClicked}}>
        {{@themeInstance.editRowButtonLabelMsg}}
      </button>
    {{/if}}
    {{yield}}
  </div>
  */
  {
    "id": "tK548BaT",
    "block": "[[[11,0],[4,[38,0],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[11,\"button\"],[16,0,[29,[[30,2,[\"buttonDefault\"]],\" \",[30,2,[\"cancelRowButton\"]]]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"cancelClicked\"]]],null],[12],[1,\"\\n      \"],[1,[30,2,[\"cancelRowButtonLabelMsg\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[11,\"button\"],[16,0,[29,[[30,2,[\"buttonDefault\"]],\" \",[30,2,[\"saveRowButton\"]]]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"saveClicked\"]]],null],[12],[1,\"\\n      \"],[1,[30,2,[\"saveRowButtonLabelMsg\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[11,\"button\"],[16,0,[29,[[30,2,[\"buttonDefault\"]],\" \",[30,2,[\"editRowButton\"]]]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"editClicked\"]]],null],[12],[1,\"\\n      \"],[1,[30,2,[\"editRowButtonLabelMsg\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[18,3,null],[1,\"\\n\"],[13]],[\"@isEditRow\",\"@themeInstance\",\"&default\"],false,[\"on\",\"if\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/cell-edit-toggle.hbs",
    "isStrictMode": false
  });
  /**
   * Component for cells used as toggle for edit-mode.
   *
   * Properties and event-handlers from {@link DefaultTheme.Cell | Cell} are bound here
   *
   * Usage example:
   *
   * ```html
   * <ModelsTable
   *   @data={{this.data}}
   *   @columns={{this.columns}}
   *   @columnComponents={{hash
   *     editRow=(component
   *       "models-table/cell-edit-toggle"
   *       onSaveRow=this.onSaveRow
   *       onCancelRow=this.onCancelRow"
   *   )}}
   * />
   * ```
   *
   * ```js
   * import Controller from '@ember/controller';
   * import {action} from '@ember/object';
   *
   * export default class InLineEditController extends Controller {
   *
   *   data = [];
   *
   *   columns = [
   *     {propertyName: 'firstName'},
   *     {propertyName: 'lastName'},
   *     {
   *       title: 'Edit',
   *       component: 'editRow',
   *       editable: false // <--- IMPORTANT
   *     }
   *   ];
   *
   *   @action
   *   onSaveRow(param) {
   *     return param.record.save();
   *   }
   *
   *   @action
   *   onCancelRow({record}) {
   *     record.rollbackAttributes();
   *     return true;
   *   }
   * }
   * ```
   */
  let CellEditToggle = (_class = class CellEditToggle extends _component2.default {
    onClick(event) {
      event?.stopPropagation();
    }

    /**
     * Fires when "Save" button is clicked. Edit-mode for row is turned off
     *
     * @event saveClicked
     */
    saveClicked() {
      let actionResult = true;
      if (this.args.onSaveRow) {
        actionResult = this.args.onSaveRow({
          record: this.args.record
        });
      }
      (0, _rsvp.resolve)(actionResult).then(result => {
        if (result) {
          this.args.saveRow();
        }
      });
    }

    /**
     * Fires when "Edit" button is clicked. Edit-mode for row is turned on
     *
     * @event editClicked
     */
    editClicked() {
      let actionResult = true;
      if (this.args.onEditRow) {
        actionResult = this.args.onEditRow({
          record: this.args.record
        });
      }
      (0, _rsvp.resolve)(actionResult).then(result => {
        if (result) {
          this.args.editRow();
        }
      });
    }

    /**
     * Fires when "Cancel Edit" button is clicked. Edit-mode for row is turned off
     *
     * @event cancelClicked
     */
    cancelClicked() {
      let actionResult = true;
      if (this.args.onCancelRow) {
        actionResult = this.args.onCancelRow({
          record: this.args.record
        });
      }
      (0, _rsvp.resolve)(actionResult).then(result => {
        if (result) {
          this.args.cancelEditRow();
        }
      });
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveClicked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "editClicked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelClicked", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelClicked"), _class.prototype)), _class);
  _exports.default = CellEditToggle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CellEditToggle);
});