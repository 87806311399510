define("ember-models-table/services/emt-themes/bootstrap3", ["exports", "@embroider/util", "ember-models-table/services/emt-themes/default", "ember-models-table/components/models-table/themes/bootstrap3/row-select-all-checkbox"], function (_exports, _util, _default, _rowSelectAllCheckbox) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class Bootstrap3Theme extends _default.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "table", 'table table-striped table-bordered table-condensed');
      _defineProperty(this, "buttonsGroup", 'btn-group');
      _defineProperty(this, "globalFilterWrapper", 'pull-left');
      _defineProperty(this, "sortGroupedPropertyBtn", 'btn btn-default');
      _defineProperty(this, "columnsDropdownWrapper", 'pull-right columns-dropdown');
      _defineProperty(this, "columnsDropdown", 'dropdown-menu pull-right');
      _defineProperty(this, "columnsDropdownDivider", 'divider');
      _defineProperty(this, "dataGroupBySelectWrapper", 'data-group-by-wrapper pull-left');
      _defineProperty(this, "footerSummaryNumericPagination", 'col-md-4 col-sm-4 col-xs-4');
      _defineProperty(this, "footerSummaryDefaultPagination", 'col-md-5 col-sm-5 col-xs-5');
      _defineProperty(this, "pageSizeWrapper", 'col-md-2 col-sm-2 col-xs-2');
      _defineProperty(this, "pageSizeSelectWrapper", 'pull-left');
      _defineProperty(this, "currentPageSizeSelectWrapper", 'pull-right');
      _defineProperty(this, "paginationInternalWrapper", 'btn-toolbar pull-right');
      _defineProperty(this, "paginationWrapperNumeric", 'col-md-6 col-sm-6 col-xs-6');
      _defineProperty(this, "paginationWrapperDefault", 'col-md-5 col-sm-5 col-xs-5');
      _defineProperty(this, "paginationBlock", 'btn-group');
      _defineProperty(this, "paginationNumericItemActive", 'active');
      _defineProperty(this, "buttonDefault", 'btn btn-default');
      _defineProperty(this, "buttonLink", 'btn btn-link');
      _defineProperty(this, "form", 'form-inline');
      _defineProperty(this, "formElementWrapper", 'form-group');
      _defineProperty(this, "input", 'form-control');
      _defineProperty(this, "select", '');
      _defineProperty(this, "tfooterWrapper", 'table-footer clearfix');
      _defineProperty(this, "tfooterInternalWrapper", 'row');
      _defineProperty(this, "clearFilterIcon", 'glyphicon glyphicon-remove-sign form-control-feedback');
      _defineProperty(this, "clearAllFiltersIcon", 'glyphicon glyphicon-remove-circle');
      _defineProperty(this, "sortAscIcon", 'glyphicon glyphicon-triangle-top');
      _defineProperty(this, "sortDescIcon", 'glyphicon glyphicon-triangle-bottom');
      _defineProperty(this, "columnVisibleIcon", 'glyphicon glyphicon-check');
      _defineProperty(this, "columnHiddenIcon", 'glyphicon glyphicon-unchecked');
      _defineProperty(this, "navFirstIcon", 'glyphicon glyphicon-chevron-left');
      _defineProperty(this, "navPrevIcon", 'glyphicon glyphicon-menu-left');
      _defineProperty(this, "navNextIcon", 'glyphicon glyphicon-menu-right');
      _defineProperty(this, "navLastIcon", 'glyphicon glyphicon-chevron-right');
      _defineProperty(this, "caretIcon", 'caret');
      _defineProperty(this, "expandRowIcon", 'glyphicon glyphicon-plus');
      _defineProperty(this, "expandAllRowsIcon", 'glyphicon glyphicon-plus');
      _defineProperty(this, "collapseRowIcon", 'glyphicon glyphicon-minus');
      _defineProperty(this, "collapseAllRowsIcon", 'glyphicon glyphicon-minus');
      _defineProperty(this, "selectSomeRowsIcon", 'glyphicon glyphicon-minus');
      _defineProperty(this, "selectAllRowsIcon", 'glyphicon glyphicon-check');
      _defineProperty(this, "deselectAllRowsIcon", 'glyphicon glyphicon-unchecked');
      _defineProperty(this, "selectRowIcon", 'glyphicon glyphicon-check');
      _defineProperty(this, "deselectRowIcon", 'glyphicon glyphicon-unchecked');
      _defineProperty(this, "editRowButton", 'btn btn-default');
      _defineProperty(this, "saveRowButton", 'btn btn-default');
      _defineProperty(this, "cancelRowButton", 'btn btn-default');
    }
    /**
     * @default 'models-table/themes/bootstrap3/row-select-all-checkbox'
     */
    get rowSelectAllCheckboxComponent() {
      return (0, _util.ensureSafeComponent)(_rowSelectAllCheckbox.default, this);
    }
  }
  _exports.default = Bootstrap3Theme;
});