define("ember-in-viewport/-private/raf-admin", ["exports", "raf-pool", "ember-in-viewport/utils/is-in-viewport"], function (_exports, _rafPool, _isInViewport) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.startRAF = startRAF;
  /**
   * ensure use on requestAnimationFrame, no matter how many components
   * on the page are using this mixin
   *
   * @class RAFAdmin
   */
  class RAFAdmin {
    /** @private **/
    constructor() {
      this._rafPool = new _rafPool.default();
      this.elementRegistry = new WeakMap();
    }
    add() {
      return this._rafPool.add(...arguments);
    }
    flush() {
      return this._rafPool.flush();
    }
    remove() {
      return this._rafPool.remove(...arguments);
    }
    reset() {
      this._rafPool.reset(...arguments);
      this._rafPool.stop(...arguments);
    }

    /**
     * We provide our own element registry to add callbacks the user creates
     *
     * @method addEnterCallback
     * @param {HTMLElement} element
     * @param {Function} enterCallback
     */
    addEnterCallback(element, enterCallback) {
      this.elementRegistry.set(element, Object.assign({}, this.elementRegistry.get(element), {
        enterCallback
      }));
    }

    /**
     * We provide our own element registry to add callbacks the user creates
     *
     * @method addExitCallback
     * @param {HTMLElement} element
     * @param {Function} exitCallback
     */
    addExitCallback(element, exitCallback) {
      this.elementRegistry.set(element, Object.assign({}, this.elementRegistry.get(element), {
        exitCallback
      }));
    }
  }

  /**
   * This is a recursive function that adds itself to raf-pool to be executed on a set schedule
   *
   * @method startRAF
   * @param {HTMLElement} element
   * @param {Object} configurationOptions
   * @param {Function} enterCallback
   * @param {Function} exitCallback
   * @param {Function} addRAF
   * @param {Function} removeRAF
   */
  _exports.default = RAFAdmin;
  function startRAF(element, _ref, enterCallback, exitCallback, addRAF,
  // bound function from service to add elementId to raf pool
  removeRAF // bound function from service to remove elementId to raf pool
  ) {
    let {
      scrollableArea,
      viewportTolerance,
      viewportSpy = false
    } = _ref;
    const domScrollableArea = typeof scrollableArea === 'string' && scrollableArea ? document.querySelector(scrollableArea) : scrollableArea instanceof HTMLElement ? scrollableArea : undefined;
    const height = domScrollableArea ? domScrollableArea.offsetHeight + domScrollableArea.getBoundingClientRect().top : window.innerHeight;
    const width = scrollableArea ? domScrollableArea.offsetWidth + domScrollableArea.getBoundingClientRect().left : window.innerWidth;
    const boundingClientRect = element.getBoundingClientRect();
    if (boundingClientRect) {
      const viewportEntered = element.getAttribute('data-in-viewport-entered');
      triggerDidEnterViewport(element, (0, _isInViewport.default)(boundingClientRect, height, width, viewportTolerance), viewportSpy, enterCallback, exitCallback, viewportEntered);
      if (viewportSpy || viewportEntered !== 'true') {
        // recursive
        // add to pool of requestAnimationFrame listeners and executed on set schedule
        addRAF(startRAF.bind(this, element, {
          scrollableArea,
          viewportTolerance,
          viewportSpy
        }, enterCallback, exitCallback, addRAF, removeRAF));
      } else {
        removeRAF();
      }
    }
  }
  function triggerDidEnterViewport(element, hasEnteredViewport, viewportSpy, enterCallback, exitCallback) {
    let viewportEntered = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : false;
    const didEnter = (!viewportEntered || viewportEntered === 'false') && hasEnteredViewport;
    const didLeave = viewportEntered === 'true' && !hasEnteredViewport;
    if (didEnter) {
      element.setAttribute('data-in-viewport-entered', true);
      enterCallback();
    }
    if (didLeave) {
      exitCallback();

      // reset so we can call again
      if (viewportSpy) {
        element.setAttribute('data-in-viewport-entered', false);
      }
    }
  }
});