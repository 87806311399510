define("ember-models-table/components/models-table/themes/ember-paper/expand-all-toggle", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/expand-all-toggle", "ember-models-table/utils/emt/themes/ep"], function (_exports, _component, _templateFactory, _util, _expandAllToggle, _ep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <this.PaperButton @onClick={{this.doExpandAllRows}} @iconButton={{true}}>
    <this.PaperIcon @icon={{@themeInstance.expandAllRowsIcon}} class={{@themeInstance.expandAllRowsIcon}}/>
  </this.PaperButton>
  <br>
  <this.PaperButton @onClick={{this.doCollapseAllRows}} @iconButton={{true}}>
    <this.PaperIcon @icon={{@themeInstance.collapseAllRowsIcon}} class={{@themeInstance.collapseAllRowsIcon}}/>
  </this.PaperButton>
  {{yield}}
  */
  {
    "id": "U951kmHL",
    "block": "[[[8,[30,0,[\"PaperButton\"]],null,[[\"@onClick\",\"@iconButton\"],[[30,0,[\"doExpandAllRows\"]],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,0,[\"PaperIcon\"]],[[16,0,[30,1,[\"expandAllRowsIcon\"]]]],[[\"@icon\"],[[30,1,[\"expandAllRowsIcon\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,\"br\"],[12],[13],[1,\"\\n\"],[8,[30,0,[\"PaperButton\"]],null,[[\"@onClick\",\"@iconButton\"],[[30,0,[\"doCollapseAllRows\"]],true]],[[\"default\"],[[[[1,\"\\n  \"],[8,[30,0,[\"PaperIcon\"]],[[16,0,[30,1,[\"collapseAllRowsIcon\"]]]],[[\"@icon\"],[[30,1,[\"collapseAllRowsIcon\"]]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[18,2,null]],[\"@themeInstance\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/expand-all-toggle.hbs",
    "isStrictMode": false
  });
  class ExpandAllToggle extends _expandAllToggle.default {
    get PaperButton() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperButton)(), this);
    }
    get PaperIcon() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperIcon)(), this);
    }
  }
  _exports.default = ExpandAllToggle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExpandAllToggle);
});