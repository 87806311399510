define("ember-models-table/components/models-table/themes/default/cell", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "@glimmer/component", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _util, _component2, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-invalid-interactive  --}}
  <td
    {{on "click" this.onClick}}
    class="{{@column.className}}">
    {{#if (has-block)}}
      {{yield
        (hash
          record=@record
          index=@index
          column=@column
          componentToRender=this.componentToRender
          groupedLength=@groupedLength
          expandRow=@expandRow
          collapseRow=@collapseRow
          expandAllRows=@expandAllRows
          collapseAllRows=@collapseAllRows
          editRow=@editRow
          cancelEditRow=@cancelEditRow
          themeInstance=@themeInstance
          clickOnRow=@clickOnRow
          isExpanded=@isExpanded
          isSelected=@isSelected
          isEditRow=@isEditRow
          saveRow=@saveRow
          isColumnEditable=this.isColumnEditable
        )
      }}
    {{else}}
      {{#if this.componentToRender}}
        {{component
          (ensure-safe-component this.componentToRender)
          record=@record
          index=@index
          column=@column
          isEditRow=@isEditRow
          editRow=@editRow
          saveRow=@saveRow
          cancelEditRow=@cancelEditRow
          expandRow=@expandRow
          collapseRow=@collapseRow
          expandAllRows=@expandAllRows
          collapseAllRows=@collapseAllRows
          clickOnRow=@clickOnRow
          isExpanded=@isExpanded
          isSelected=@isSelected
          isColumnEditable=this.isColumnEditable
          groupedLength=@groupedLength
          themeInstance=@themeInstance
        }}
      {{/if}}
    {{/if}}
  </td>
  */
  {
    "id": "1NGxOzXr",
    "block": "[[[11,\"td\"],[16,0,[29,[[30,1,[\"className\"]]]]],[4,[38,0],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,17]],[[[1,\"    \"],[18,17,[[28,[37,4],null,[[\"record\",\"index\",\"column\",\"componentToRender\",\"groupedLength\",\"expandRow\",\"collapseRow\",\"expandAllRows\",\"collapseAllRows\",\"editRow\",\"cancelEditRow\",\"themeInstance\",\"clickOnRow\",\"isExpanded\",\"isSelected\",\"isEditRow\",\"saveRow\",\"isColumnEditable\"],[[30,2],[30,3],[30,1],[30,0,[\"componentToRender\"]],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[30,14],[30,15],[30,16],[30,0,[\"isColumnEditable\"]]]]]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"componentToRender\"]],[[[1,\"      \"],[46,[28,[37,6],[[30,0,[\"componentToRender\"]]],null],null,[[\"record\",\"index\",\"column\",\"isEditRow\",\"editRow\",\"saveRow\",\"cancelEditRow\",\"expandRow\",\"collapseRow\",\"expandAllRows\",\"collapseAllRows\",\"clickOnRow\",\"isExpanded\",\"isSelected\",\"isColumnEditable\",\"groupedLength\",\"themeInstance\"],[[30,2],[30,3],[30,1],[30,15],[30,9],[30,16],[30,10],[30,5],[30,6],[30,7],[30,8],[30,12],[30,13],[30,14],[30,0,[\"isColumnEditable\"]],[30,4],[30,11]]],null],[1,\"\\n\"]],[]],null]],[]]],[13]],[\"@column\",\"@record\",\"@index\",\"@groupedLength\",\"@expandRow\",\"@collapseRow\",\"@expandAllRows\",\"@collapseAllRows\",\"@editRow\",\"@cancelEditRow\",\"@themeInstance\",\"@clickOnRow\",\"@isExpanded\",\"@isSelected\",\"@isEditRow\",\"@saveRow\",\"&default\"],false,[\"on\",\"if\",\"has-block\",\"yield\",\"hash\",\"component\",\"ensure-safe-component\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/cell.hbs",
    "isStrictMode": false
  });
  /**
   * Table cell used within {@link Row}.
   *
   * Cell types:
   *
   * * Text. Shows some property value
   * * Route link with record id
   * * Route link with record property value
   * * Custom component
   *
   * See properties {@link Core.ModelsTableColumn.routeName}, {@link Core.ModelsTableColumn.propertyName}, {@link Core.ModelsTableColumn.component}.
   *
   * Usage example:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#each MT.visibleContent as |record index|}}
   *         <Body.Row @record={{record}} @index={{index}} as |Row|>
   *           {{#each MT.visibleProcessedColumns as |column|}}
   *             <Row.Cell @column={{column}} @index={{index}} as |Cell|/>
   *               {{#if Cell.componentToRender}}
   *                 {{component Cell.componentToRender record=Cell.record column=column index=index}}
   *               {{/if}}
   *               {{! ... }}
   *             </Row.Cell>
   *           {{/each}}
   *         </Body.Row>
   *       {{/each}}
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   */
  let Cell = (_class = class Cell extends _component2.default {
    /**
     * Is this column editable
     *
     * @default false
     */
    get isColumnEditable() {
      let isEditable = this.args.isEditRow;
      if (isEditable) {
        const columnEditable = this.args.column.editable;
        if (typeof columnEditable === 'function') {
          isEditable = columnEditable() || false;
        } else if (!columnEditable) {
          isEditable = false;
        }
      }
      return isEditable;
    }

    /**
     * Given the mode for a cell (Edit or not) will determine which component to render
     */
    get componentToRender() {
      if ((0, _utils.isNone)(this.args.column.propertyName)) {
        return null;
      }
      let cellEditComponent;
      if (this.isColumnEditable) {
        if (this.args.columnComponents) {
          cellEditComponent = this.args.column.componentForEdit ? typeof this.args.column.componentForEdit === 'string' ? (0, _object.get)(this.args.columnComponents, this.args.column.componentForEdit) : this.args.column.componentForEdit : this.args.themeInstance.cellContentEditComponent;
        } else {
          cellEditComponent = this.args.column.componentForEdit || this.args.themeInstance.cellContentEditComponent;
        }
      }
      let cellDisplayComponent;
      if (this.args.columnComponents) {
        cellDisplayComponent = this.args.column.component ? typeof this.args.column.component === 'string' ? (0, _object.get)(this.args.columnComponents, this.args.column.component) : this.args.column.component : this.args.themeInstance.cellContentDisplayComponent;
      } else {
        cellDisplayComponent = this.args.column.component || this.args.themeInstance.cellContentDisplayComponent;
      }
      return (0, _util.ensureSafeComponent)(cellEditComponent, this) || (0, _util.ensureSafeComponent)(cellDisplayComponent, this);
    }

    /**
     * @event onClick
     */
    onClick(e) {
      if (this.args.isEditRow) {
        e?.stopPropagation?.();
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  _exports.default = Cell;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Cell);
});