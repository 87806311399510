define("ember-models-table/components/models-table/themes/ember-paper/cell-edit-toggle", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/cell-edit-toggle", "ember-models-table/utils/emt/themes/ep"], function (_exports, _component, _templateFactory, _util, _cellEditToggle, _ep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-invalid-interactive  --}}
  <div {{on "click" this.onClick}}>
    {{#if this.isEditRow}}
      <this.PaperButton
        class="{{@themeInstance.buttonDefault}} {{@themeInstance.cancelRowButton}}"
        @raised={{true}}
        @onClick={{this.cancelClicked}}>
        {{this.cancelButtonLabel}}
      </this.PaperButton>
      <this.PaperButton
        class="{{@themeInstance.buttonDefault}} {{@themeInstance.saveRowButton}}"
        @raised={{true}}
        @onClick={{this.saveClicked}}>
        {{this.saveButtonLabel}}
      </this.PaperButton>
    {{else}}
      <this.PaperButton
        class="{{@themeInstance.buttonDefault}} {{@themeInstance.editRowButton}}"
        @raised={{true}}
        @onClick={{this.editClicked}}>
        {{this.editButtonLabel}}
      </this.PaperButton>
    {{/if}}
    {{yield}}
  </div>
  */
  {
    "id": "sUAbsEmA",
    "block": "[[[11,0],[4,[38,0],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"isEditRow\"]],[[[1,\"    \"],[8,[30,0,[\"PaperButton\"]],[[16,0,[29,[[30,1,[\"buttonDefault\"]],\" \",[30,1,[\"cancelRowButton\"]]]]]],[[\"@raised\",\"@onClick\"],[true,[30,0,[\"cancelClicked\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,0,[\"cancelButtonLabel\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,0,[\"PaperButton\"]],[[16,0,[29,[[30,1,[\"buttonDefault\"]],\" \",[30,1,[\"saveRowButton\"]]]]]],[[\"@raised\",\"@onClick\"],[true,[30,0,[\"saveClicked\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,0,[\"saveButtonLabel\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[30,0,[\"PaperButton\"]],[[16,0,[29,[[30,1,[\"buttonDefault\"]],\" \",[30,1,[\"editRowButton\"]]]]]],[[\"@raised\",\"@onClick\"],[true,[30,0,[\"editClicked\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[30,0,[\"editButtonLabel\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"],[18,2,null],[1,\"\\n\"],[13]],[\"@themeInstance\",\"&default\"],false,[\"on\",\"if\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/cell-edit-toggle.hbs",
    "isStrictMode": false
  });
  class CellEditToggle extends _cellEditToggle.default {
    get PaperButton() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperButton)(), this);
    }
  }
  _exports.default = CellEditToggle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CellEditToggle);
});