define("ember-models-table/components/models-table/themes/plain-html/summary", ["exports", "@ember/component", "@ember/template-factory", "ember-models-table/components/models-table/themes/default/summary"], function (_exports, _component, _templateFactory, _summary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{@themeInstance.footerSummary}} {{this.paginationTypeClass}}">
    {{#if (has-block)}}
      {{yield
        (hash
          summary=this.summary
        )
      }}
    {{else}}
      {{this.summary}}
      <button
        type="button"
        class="clearFilters {{unless @anyFilterUsed "emt-sr-only"}}"
        {{on "click" this.doClearFilters}}>
        <i class={{@themeInstance.clearAllFiltersIcon}}></i>
        <span class="emt-sr-only">{{@themeInstance.clearAllFiltersMsg}}</span>
      </button>
    {{/if}}
  </div>
  */
  {
    "id": "0Yl9Ne7w",
    "block": "[[[10,0],[15,0,[29,[[30,1,[\"footerSummary\"]],\" \",[30,0,[\"paginationTypeClass\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"    \"],[18,3,[[28,[37,3],null,[[\"summary\"],[[30,0,[\"summary\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[30,0,[\"summary\"]]],[1,\"\\n    \"],[11,\"button\"],[16,0,[29,[\"clearFilters \",[52,[51,[30,2]],\"emt-sr-only\"]]]],[24,4,\"button\"],[4,[38,5],[\"click\",[30,0,[\"doClearFilters\"]]],null],[12],[1,\"\\n      \"],[10,\"i\"],[15,0,[30,1,[\"clearAllFiltersIcon\"]]],[12],[13],[1,\"\\n      \"],[10,1],[14,0,\"emt-sr-only\"],[12],[1,[30,1,[\"clearAllFiltersMsg\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@themeInstance\",\"@anyFilterUsed\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"hash\",\"unless\",\"on\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/plain-html/summary.hbs",
    "isStrictMode": false
  });
  class Summary extends _summary.default {}
  _exports.default = Summary;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Summary);
});