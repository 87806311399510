define("ember-models-table/components/models-table/themes/ember-paper/global-filter", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/global-filter", "ember-models-table/utils/emt/themes/ep"], function (_exports, _component, _templateFactory, _util, _globalFilter, _ep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="globalSearch {{@themeInstance.globalFilterWrapper}}">
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      <this.PaperInput
        class="filterString {{@themeInstance.input}}"
        @label={{@themeInstance.searchLabelMsg}}
        @placeholder={{@themeInstance.searchPlaceholderMsg}}
        @value={{@value}}
        @onChange={{this.updateGlobalFilterString}}/>
      {{#if @globalFilterUsed}}
        <this.PaperButton
          class="clearFilterIcon {{@themeInstance.clearFilterIcon}}"
          @onClick={{this.clearGlobalFilter}}
          @iconButton={{true}}>
          <this.PaperIcon @icon="close"/>
        </this.PaperButton>
      {{/if}}
    {{/if}}
  </div>
  */
  {
    "id": "JIh60dfm",
    "block": "[[[10,0],[15,0,[29,[\"globalSearch \",[30,1,[\"globalFilterWrapper\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[30,0,[\"PaperInput\"]],[[16,0,[29,[\"filterString \",[30,1,[\"input\"]]]]]],[[\"@label\",\"@placeholder\",\"@value\",\"@onChange\"],[[30,1,[\"searchLabelMsg\"]],[30,1,[\"searchPlaceholderMsg\"]],[30,2],[30,0,[\"updateGlobalFilterString\"]]]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"      \"],[8,[30,0,[\"PaperButton\"]],[[16,0,[29,[\"clearFilterIcon \",[30,1,[\"clearFilterIcon\"]]]]]],[[\"@onClick\",\"@iconButton\"],[[30,0,[\"clearGlobalFilter\"]],true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[30,0,[\"PaperIcon\"]],null,[[\"@icon\"],[\"close\"]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]],null]],[]]],[13]],[\"@themeInstance\",\"@value\",\"@globalFilterUsed\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/global-filter.hbs",
    "isStrictMode": false
  });
  class GlobalFilter extends _globalFilter.default {
    get PaperButton() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperButton)(), this);
    }
    get PaperIcon() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperIcon)(), this);
    }
    get PaperInput() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperInput)(), this);
    }
  }
  _exports.default = GlobalFilter;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GlobalFilter);
});