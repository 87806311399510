define("ember-models-table/components/models-table/themes/default/global-filter", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object/internals", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _internals, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="{{@themeInstance.globalFilterWrapper}}">
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      <div class="globalSearch {{@themeInstance.form}}">
        <div class={{@themeInstance.formElementWrapper}}>
          <label for={{this.inputId}}>{{@themeInstance.searchLabelMsg}}</label> <Input
            id={{this.inputId}}
            @type="text"
            @value={{@value}}
            class="filterString {{@themeInstance.input}}"
            @enter={{this.noop}}
            {{on "input" this.updateGlobalFilterString}}
            placeholder={{@themeInstance.searchPlaceholderMsg}}/>
          {{#if @globalFilterUsed}}
            <i
              class="clearFilterIcon {{@themeInstance.clearFilterIcon}}"
              role="button"
              aria-label={{@themeInstance.clearGlobalFilterMsg}}
              {{on "click" this.clearGlobalFilter}}>
            </i>
          {{/if}}
        </div>
      </div>
    {{/if}}
  </div>
  */
  {
    "id": "ew3vAfrU",
    "block": "[[[10,0],[15,0,[29,[[30,1,[\"globalFilterWrapper\"]]]]],[12],[1,\"\\n\"],[41,[48,[30,4]],[[[1,\"    \"],[18,4,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[29,[\"globalSearch \",[30,1,[\"form\"]]]]],[12],[1,\"\\n      \"],[10,0],[15,0,[30,1,[\"formElementWrapper\"]]],[12],[1,\"\\n        \"],[10,\"label\"],[15,\"for\",[30,0,[\"inputId\"]]],[12],[1,[30,1,[\"searchLabelMsg\"]]],[13],[1,\" \"],[8,[39,3],[[16,1,[30,0,[\"inputId\"]]],[16,0,[29,[\"filterString \",[30,1,[\"input\"]]]]],[16,\"placeholder\",[30,1,[\"searchPlaceholderMsg\"]]],[4,[38,4],[\"input\",[30,0,[\"updateGlobalFilterString\"]]],null]],[[\"@type\",\"@value\",\"@enter\"],[\"text\",[30,2],[30,0,[\"noop\"]]]],null],[1,\"\\n\"],[41,[30,3],[[[1,\"          \"],[11,\"i\"],[16,0,[29,[\"clearFilterIcon \",[30,1,[\"clearFilterIcon\"]]]]],[24,\"role\",\"button\"],[16,\"aria-label\",[30,1,[\"clearGlobalFilterMsg\"]]],[4,[38,4],[\"click\",[30,0,[\"clearGlobalFilter\"]]],null],[12],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@themeInstance\",\"@value\",\"@globalFilterUsed\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"input\",\"on\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/global-filter.hbs",
    "isStrictMode": false
  });
  /**
   * Global filter element used within {@link Core.ModelsTable | ModelsTable}.
   *
   * Its value is used for all data-items and for each columns {@link Core.ModelsTableColumn.filterField | filterField}.
   *
   * Usage example:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.GlobalFilter />
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Block usage example:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.GlobalFilter>
   *     {{input value=MT.globalFilter}}
   *     <button disabled={{if MT.globalFilterUsed "disabled"}} {{on "click" this.clearGlobalFilter}}>
   *       Clear Global Filter
   *     </button>
   *   </MT.GlobalFilter>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   */
  let GlobalFilter = (_class = class GlobalFilter extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "elementId", (0, _internals.guidFor)(this));
    }
    get inputId() {
      return `${this.elementId}-global-filter`;
    }
    getNewFilterValueFromEvent(e) {
      return typeof e === 'object' ? e.target.value : `${e}`;
    }

    /**
     * @event noop
     */
    noop() {
      // do nothing.
    }

    /**
     * @event updateGlobalFilterString
     */
    updateGlobalFilterString(e) {
      const newFilterValue = this.getNewFilterValueFromEvent(e);
      this.args.changeGlobalFilter(newFilterValue);
      return false;
    }

    /**
     * @event clearGlobalFilter
     */
    clearGlobalFilter(e) {
      e?.stopPropagation?.();
      this.args.changeGlobalFilter('');
      return false;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "noop", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "noop"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateGlobalFilterString", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateGlobalFilterString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearGlobalFilter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearGlobalFilter"), _class.prototype)), _class);
  _exports.default = GlobalFilter;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GlobalFilter);
});