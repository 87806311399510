define("ember-models-table/components/models-table/themes/default/row-select-all-checkbox", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <button
    {{on "click" this.doToggleAllSelection}}
    type="button"
    class="toggle-all {{@themeInstance.buttonLink}}">
    <i class={{if
      (is-equal @selectedItems.length @data.length)
      @themeInstance.selectAllRowsIcon
      (if @selectedItems.length
        @themeInstance.selectSomeRowsIcon
        @themeInstance.deselectAllRowsIcon)
    }}></i>
  </button>
  {{yield}}
  */
  {
    "id": "Xgx3tnTn",
    "block": "[[[11,\"button\"],[16,0,[29,[\"toggle-all \",[30,1,[\"buttonLink\"]]]]],[24,4,\"button\"],[4,[38,0],[\"click\",[30,0,[\"doToggleAllSelection\"]]],null],[12],[1,\"\\n  \"],[10,\"i\"],[15,0,[52,[28,[37,2],[[30,2,[\"length\"]],[30,3,[\"length\"]]],null],[30,1,[\"selectAllRowsIcon\"]],[52,[30,2,[\"length\"]],[30,1,[\"selectSomeRowsIcon\"]],[30,1,[\"deselectAllRowsIcon\"]]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[18,4,null]],[\"@themeInstance\",\"@selectedItems\",\"@data\",\"&default\"],false,[\"on\",\"if\",\"is-equal\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/row-select-all-checkbox.hbs",
    "isStrictMode": false
  });
  /**
   * Component with checkbox to select or deselect all rows
   *
   * Usage example:
   *
   * ```js
   * const columns = [
   *   {
   *     component: 'models-table/row-select-checkbox',
   *     disableFiltering: true,
   *     mayBeHidden: false,
   *     componentForSortCell: 'models-table/row-select-all-checkbox'
   *   },
   *   {propertyName: 'firstName'},
   *   {propertyName: 'lastName'}
   * ];
   * const data = [ ... ];
   * ```
   *
   * ```html
   * <ModelsTable
   *   @data={{this.data}}
   *   @columns={{this.columns}}
   *   @multipleSelect={{true}}
   * />
   * ```
   */
  let RowSelectAllCheckbox = (_class = class RowSelectAllCheckbox extends _component2.default {
    /**
     * @event doToggleAllSelection
     */
    doToggleAllSelection() {
      this.args.toggleAllSelection();
      return false;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "doToggleAllSelection", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doToggleAllSelection"), _class.prototype)), _class);
  _exports.default = RowSelectAllCheckbox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RowSelectAllCheckbox);
});