define("ember-models-table/components/models-table/themes/default/row-sorting-cell", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object", "ember-models-table/utils/emt/string"], function (_exports, _component, _templateFactory, _component2, _object, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <th
    class="{{@themeInstance.theadCell}} {{@column.className}}"
    colspan="{{@column.realColspanForSortCell}}">
    {{#if @column.componentForSortCell}}
      {{#let
        (component (ensure-safe-component @column.componentForSortCell)
          column=@column
          selectedItems=@selectedItems
          expandedItems=@expandedItems
          data=@data
          themeInstance=@themeInstance
          expandAllRows=@expandAllRows
          toggleAllSelection=@toggleAllSelection
          collapseAllRows=@collapseAllRows
          sortingIndex=this.sortingIndex
          sortColumn=this.onClick
        )
      as |CellContent|
      }}
        {{#if (has-block)}}
          {{yield CellContent}}
        {{else}}
          <CellContent/>
        {{/if}}
      {{/let}}
    {{else}}
      {{#if (has-block)}}
        {{yield
          (hash
            sortColumn=this.onClick
          )
        }}
      {{else}}
        <span role="button" tabindex="0" {{on "click" this.onClick}}>
          {{@column.columnTitle}}
          {{#if @column.useSorting}}
            <i class="{{if @column.sortAsc @themeInstance.sortAscIcon}} {{if @column.sortDesc @themeInstance.sortDescIcon}}">
            </i>
          {{/if}}
        </span>
      {{/if}}
    {{/if}}
  </th>
  */
  {
    "id": "SgMiIC3E",
    "block": "[[[10,\"th\"],[15,0,[29,[[30,1,[\"theadCell\"]],\" \",[30,2,[\"className\"]]]]],[15,\"colspan\",[29,[[30,2,[\"realColspanForSortCell\"]]]]],[12],[1,\"\\n\"],[41,[30,2,[\"componentForSortCell\"]],[[[44,[[50,[28,[37,3],[[30,2,[\"componentForSortCell\"]]],null],0,null,[[\"column\",\"selectedItems\",\"expandedItems\",\"data\",\"themeInstance\",\"expandAllRows\",\"toggleAllSelection\",\"collapseAllRows\",\"sortingIndex\",\"sortColumn\"],[[30,2],[30,3],[30,4],[30,5],[30,1],[30,6],[30,7],[30,8],[30,0,[\"sortingIndex\"]],[30,0,[\"onClick\"]]]]]],[[[41,[48,[30,10]],[[[1,\"        \"],[18,10,[[30,9]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[30,9],null,null,null],[1,\"\\n\"]],[]]]],[9]]]],[]],[[[41,[48,[30,10]],[[[1,\"      \"],[18,10,[[28,[37,6],null,[[\"sortColumn\"],[[30,0,[\"onClick\"]]]]]]],[1,\"\\n\"]],[]],[[[1,\"      \"],[11,1],[24,\"role\",\"button\"],[24,\"tabindex\",\"0\"],[4,[38,7],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n        \"],[1,[30,2,[\"columnTitle\"]]],[1,\"\\n\"],[41,[30,2,[\"useSorting\"]],[[[1,\"          \"],[10,\"i\"],[15,0,[29,[[52,[30,2,[\"sortAsc\"]],[30,1,[\"sortAscIcon\"]]],\" \",[52,[30,2,[\"sortDesc\"]],[30,1,[\"sortDescIcon\"]]]]]],[12],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]]]],[]]],[13]],[\"@themeInstance\",\"@column\",\"@selectedItems\",\"@expandedItems\",\"@data\",\"@expandAllRows\",\"@toggleAllSelection\",\"@collapseAllRows\",\"CellContent\",\"&default\"],false,[\"if\",\"let\",\"component\",\"ensure-safe-component\",\"has-block\",\"yield\",\"hash\",\"on\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/row-sorting-cell.hbs",
    "isStrictMode": false
  });
  /**
   * Sort-row cell used within {@link DefaultTheme.RowSorting}.
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowSorting as |RS|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <RS.RowSortingCell @column={{column}} />
   *         {{/each}}
   *       </Header.RowSorting>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   * If {@link Core.ModelsTableColumn.componentForSortCell} is provided it is yielded with next properties and actions:
   *
   * * `column` - {@link DefaultTheme.RowSortingCellArgs.column}
   * * `selectedItems` - {@link DefaultTheme.RowSortingCellArgs.selectedItems}
   * * `expandedItems` - {@link DefaultTheme.RowSortingCellArgs.expandedItems}
   * * `data` - {@link DefaultTheme.RowSortingCellArgs.data}
   * * `themeInstance` - {@link DefaultTheme.RowSortingCellArgs.themeInstance}
   * * `expandAllRows` - {@link DefaultTheme.RowSortingCellArgs.expandAllRows}
   * * `collapseAllRows` - {@link DefaultTheme.RowSortingCellArgs.collapseAllRows}
   * * `sortColumn` - {@link onClick}
   * * `toggleAllSelection` - {@link DefaultTheme.RowSortingCellArgs.toggleAllSelection}
   *
   * References to the following actions are yielded:
   *
   * * {@link onClick | sortColumn} - sort column asc or desc
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowSorting as |RS|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <RS.RowSortingCell @column={{column}} as |RowSortingCellContent|>
   *             <RowSortingCellContent/>
   *           </RS.RowSortingCell>
   *         {{/each}}
   *       </Header.RowSorting>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   */
  let RowSortingCell = (_class = class RowSortingCell extends _component2.default {
    get sortingIndex() {
      const {
        args: {
          column: {
            sortField
          }
        }
      } = this;
      if (!sortField) {
        return -1;
      }
      return this.args.sortProperties.findIndex(sortProp => {
        const propName = (0, _string.splitPropSortDirection)(sortProp)[0];
        return propName === sortField;
      }) + 1;
    }
    onClick() {
      if (this.args.column.useSorting) {
        this.args.sort(this.args.column);
      }
      return false;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  _exports.default = RowSortingCell;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RowSortingCell);
});