define("ember-models-table/components/models-table/themes/ember-paper/row-select-all-checkbox", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/row-select-all-checkbox", "ember-models-table/utils/emt/themes/ep"], function (_exports, _component, _templateFactory, _util, _rowSelectAllCheckbox, _ep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <this.PaperCheckbox
    @indeterminate={{and @selectedItems.length (not-eq @selectedItems.length @data.length)}}
    @value={{is-equal @selectedItems.length @data.length}}
    @onChange={{this.doToggleAllSelection}} />
  {{yield}}
  */
  {
    "id": "IOcFEY+V",
    "block": "[[[8,[30,0,[\"PaperCheckbox\"]],null,[[\"@indeterminate\",\"@value\",\"@onChange\"],[[28,[37,0],[[30,1,[\"length\"]],[28,[37,1],[[30,1,[\"length\"]],[30,2,[\"length\"]]],null]],null],[28,[37,2],[[30,1,[\"length\"]],[30,2,[\"length\"]]],null],[30,0,[\"doToggleAllSelection\"]]]],null],[1,\"\\n\"],[18,3,null]],[\"@selectedItems\",\"@data\",\"&default\"],false,[\"and\",\"not-eq\",\"is-equal\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/row-select-all-checkbox.hbs",
    "isStrictMode": false
  });
  class RowSelectAllCheckbox extends _rowSelectAllCheckbox.default {
    get PaperCheckbox() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperCheckbox)(), this);
    }
  }
  _exports.default = RowSelectAllCheckbox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RowSelectAllCheckbox);
});