define("ember-models-table/components/models-table/themes/ember-paper/row-select-checkbox", ["exports", "@ember/component", "@ember/template-factory", "@embroider/util", "ember-models-table/components/models-table/themes/default/row-select-checkbox", "ember-models-table/utils/emt/themes/ep"], function (_exports, _component, _templateFactory, _util, _rowSelectCheckbox, _ep) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <this.PaperCheckbox
    @value={{@isSelected}}
    @bubbles={{false}}
    @onChange={{fn this.doClickOnRow @index @record}} />
  {{yield}}
  */
  {
    "id": "+hl4hoif",
    "block": "[[[8,[30,0,[\"PaperCheckbox\"]],null,[[\"@value\",\"@bubbles\",\"@onChange\"],[[30,1],false,[28,[37,0],[[30,0,[\"doClickOnRow\"]],[30,2],[30,3]],null]]],null],[1,\"\\n\"],[18,4,null]],[\"@isSelected\",\"@index\",\"@record\",\"&default\"],false,[\"fn\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/ember-paper/row-select-checkbox.hbs",
    "isStrictMode": false
  });
  class RowSelectCheckbox extends _rowSelectCheckbox.default {
    get PaperCheckbox() {
      return (0, _util.ensureSafeComponent)((0, _ep.getPaperCheckbox)(), this);
    }
  }
  _exports.default = RowSelectCheckbox;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RowSelectCheckbox);
});