define("@naeka/ember-custom-actions/actions/custom", ["exports", "@naeka/ember-custom-actions/actions/action", "@naeka/ember-custom-actions/utils/deep-merge"], function (_exports, _action, _deepMerge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default(id) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return function () {
      let payload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      let actionOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
      actionOptions.data = payload;
      return new _action.default({
        id,
        model: this,
        integrated: true,
        options: (0, _deepMerge.default)({}, options, actionOptions)
      }).callAction();
    };
  }
});