define("ember-models-table/components/models-table/themes/default/expand-toggle", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @isExpanded}}
    <button
      class="{{@themeInstance.buttonLink}} {{@themeInstance.collapseRow}}"
      type="button"
      {{on "click" this.doCollapseRow}}>
      <i class={{@themeInstance.collapseRowIcon}}></i>
    </button>
  {{else}}
    <button
      class="{{@themeInstance.buttonLink}} {{@themeInstance.expandRow}}"
      type="button"
      {{on "click" this.doExpandRow}}>
      <i class={{@themeInstance.expandRowIcon}}></i>
    </button>
  {{/if}}
  {{yield}}
  */
  {
    "id": "WHsNkJk0",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"button\"],[16,0,[29,[[30,2,[\"buttonLink\"]],\" \",[30,2,[\"collapseRow\"]]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"doCollapseRow\"]]],null],[12],[1,\"\\n    \"],[10,\"i\"],[15,0,[30,2,[\"collapseRowIcon\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,\"button\"],[16,0,[29,[[30,2,[\"buttonLink\"]],\" \",[30,2,[\"expandRow\"]]]]],[24,4,\"button\"],[4,[38,1],[\"click\",[30,0,[\"doExpandRow\"]]],null],[12],[1,\"\\n    \"],[10,\"i\"],[15,0,[30,2,[\"expandRowIcon\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]],[18,3,null]],[\"@isExpanded\",\"@themeInstance\",\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/expand-toggle.hbs",
    "isStrictMode": false
  });
  /**
   * Component to expand or collapse any single row
   *
   * Usage example:
   *
   * ```js
   * const columns = [
   *   {
   *     component: 'models-table/themes/default/expand-toggle',
   *     mayBeHidden: false
   *   },
   *   {propertyName: 'firstName'},
   *   {propertyName: 'lastName'}
   * ];
   *
   * const data = [ ... ];
   * ```
   *
   * ```html
   * <ModelsTable
   *   @data={{this.data}}
   *   @columns={{this.columns}}
   *   @expandedRowComponent={{component "path/to/your-component"}}
   * />
   * ```
   */
  let ExpandToggle = (_class = class ExpandToggle extends _component2.default {
    /**
     * @event doCollapseRow
     */
    doCollapseRow(e) {
      this.args.collapseRow(this.args.index, this.args.record);
      e?.stopPropagation?.();
      return false;
    }

    /**
     * @event doExpandRow
     */
    doExpandRow(e) {
      this.args.expandRow(this.args.index, this.args.record);
      e?.stopPropagation?.();
      return false;
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "doCollapseRow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doCollapseRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "doExpandRow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "doExpandRow"), _class.prototype)), _class);
  _exports.default = ExpandToggle;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExpandToggle);
});