define("ember-models-table/components/models-table/themes/default/row-expand", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/array", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _array, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{!-- template-lint-disable no-invalid-interactive  --}}
  <tr {{on "click" this.onClick}} class="expand-row {{this.indexedClass}} {{if this.isSelected "selected-expand"}}">
    {{#let
      (component (ensure-safe-component @expandedRowComponent)
        record=@record
        visibleProcessedColumns=@visibleProcessedColumns
        index=@index
        clickOnRow=@clickOnRow
        themeInstance=@themeInstance
      )
    as |RowExpand|
    }}
      <td colspan={{this.cellColspan}}>
        {{#if (has-block)}}
          {{yield RowExpand}}
        {{else}}
          <RowExpand />
        {{/if}}
      </td>
    {{/let}}
  </tr>
  */
  {
    "id": "CI9d0o8v",
    "block": "[[[11,\"tr\"],[16,0,[29,[\"expand-row \",[30,0,[\"indexedClass\"]],\" \",[52,[30,0,[\"isSelected\"]],\"selected-expand\"]]]],[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n\"],[44,[[50,[28,[37,4],[[30,1]],null],0,null,[[\"record\",\"visibleProcessedColumns\",\"index\",\"clickOnRow\",\"themeInstance\"],[[30,2],[30,3],[30,4],[30,5],[30,6]]]]],[[[1,\"    \"],[10,\"td\"],[15,\"colspan\",[30,0,[\"cellColspan\"]]],[12],[1,\"\\n\"],[41,[48,[30,8]],[[[1,\"        \"],[18,8,[[30,7]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[8,[30,7],null,null,null],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[7]]],[13]],[\"@expandedRowComponent\",\"@record\",\"@visibleProcessedColumns\",\"@index\",\"@clickOnRow\",\"@themeInstance\",\"RowExpand\",\"&default\"],false,[\"if\",\"on\",\"let\",\"component\",\"ensure-safe-component\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/row-expand.hbs",
    "isStrictMode": false
  });
  /**
   * Extra row with additional information for original row. Component used within {@link DefaultTheme.TableBody | TableBody}.
   *
   * Row expand is a table-row with a single cell. This cell has a `colspan` equal to the shown columns count.
   * You should provide an {@link Core.ModelsTable.expandedRowComponent}. It will be rendered inside `ModelsTableRowExpand`.
   *
   * Usage example:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Body as |Body|>
   *       {{#each MT.visibleContent as |record index|}}
   *         <Body.Row @record={{record}} @index={{index}} />
   *         {{#if (contains record MT.expandedItems)}} {{! `contains` can be imported from `ember-composable-helpers` }}
   *           <Body.RowExpand @record={{record}} @index={{index}} />
   *         {{/if}}
   *       {{/each}}
   *     </Table.Body>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   */
  let RowExpand = (_class = class RowExpand extends _component2.default {
    get indexedClass() {
      return `expand-${this.args.index}`;
    }
    get isSelected() {
      return (0, _array.isArray)(this.args.selectedItems) && this.args.selectedItems.includes(this.args.record);
    }

    /**
     * @default 0
     */
    get cellColspan() {
      return Number(this.args.visibleProcessedColumns.length);
    }

    /**
     * @event onClick
     */
    onClick() {
      this.args.clickOnRow(this.args.index, this.args.record);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  _exports.default = RowExpand;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RowExpand);
});