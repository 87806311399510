define("ember-models-table/components/models-table/themes/default/select", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object/internals", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _internals, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <select
    id={{this.id}}
    disabled="{{if this.isDisabled "disabled"}}"
    class="{{@themeInstance.input}} {{@themeInstance.select}} {{@cssPropertyName}}"
    ...attributes
    {{on "change" this.onChange}}
    {{on "click" this.onClick}}>
    {{#each @options as |opt|}}
      <option
        value={{opt.value}}
        selected={{is-equal @value opt.value}}>
        {{opt.label}}
      </option>
    {{/each}}
    {{yield}}
  </select>
  */
  {
    "id": "IfLfrVkn",
    "block": "[[[11,\"select\"],[16,1,[30,0,[\"id\"]]],[16,\"disabled\",[29,[[52,[30,0,[\"isDisabled\"]],\"disabled\"]]]],[16,0,[29,[[30,1,[\"input\"]],\" \",[30,1,[\"select\"]],\" \",[30,2]]]],[17,3],[4,[38,1],[\"change\",[30,0,[\"onChange\"]]],null],[4,[38,1],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,4]],null]],null],null,[[[1,\"    \"],[10,\"option\"],[15,2,[30,5,[\"value\"]]],[15,\"selected\",[28,[37,4],[[30,6],[30,5,[\"value\"]]],null]],[12],[1,\"\\n      \"],[1,[30,5,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[5]],null],[1,\"  \"],[18,7,null],[1,\"\\n\"],[13]],[\"@themeInstance\",\"@cssPropertyName\",\"&attrs\",\"@options\",\"opt\",\"@value\",\"&default\"],false,[\"if\",\"on\",\"each\",\"-track-array\",\"is-equal\",\"yield\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/select.hbs",
    "isStrictMode": false
  });
  /**
   * Select-dropdown with a list of options. Used as page-size select and for select-filters.
   */
  let Select = (_class = class Select extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "_id", _descriptor, this);
    }
    get id() {
      return this.args.id ?? this._id;
    }
    get isDisabled() {
      return !this.args.options || !this.args.options.length;
    }
    onChange(e) {
      e.stopPropagation();
      const value = e.target?.value;
      let valueToUse = value;
      if (this.args.type === 'number') {
        valueToUse = +value;
      }
      this.args.changeValue(valueToUse);
      return false;
    }
    onClick(e) {
      e?.stopPropagation?.();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "_id", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return (0, _internals.guidFor)(this);
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onClick", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype)), _class);
  _exports.default = Select;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, Select);
});