define("ember-models-table/components/models-table/themes/default/row-sorting", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "ember-models-table/utils/emt/macros", "ember-models-table/utils/emt/emt-column"], function (_exports, _component, _templateFactory, _component2, _macros, _emtColumn) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <tr>
    {{#let
      (hash
        shouldAddExtraColumn=this.shouldAddExtraColumn
        RowSortingCell=(component
          (ensure-safe-component @themeInstance.rowSortingCellComponent)
          themeInstance=@themeInstance
          selectedItems=@selectedItems
          expandedItems=@expandedItems
          sortProperties=@sortProperties
          sort=@sort
          data=@data
          expandAllRows=@expandAllRows
          collapseAllRows=@collapseAllRows
          toggleAllSelection=@toggleAllSelection
        )
      )
    as |RowSorting|
    }}
      {{#if (has-block)}}
        {{yield RowSorting}}
      {{else}}
        {{#if this.shouldAddExtraColumn}}
          <th>
            {{#if @groupHeaderCellComponent}}
              {{component
                (ensure-safe-component @groupHeaderCellComponent)
                currentGroupingPropertyName=@currentGroupingPropertyName
              }}
            {{else}}
              {{this.currentGroupingPropertyNameTitlelized}}
            {{/if}}
          </th>
        {{/if}}
        {{#each this.shownColumns as |column|}}
          <RowSorting.RowSortingCell @column={{column}}/>
        {{/each}}
      {{/if}}
    {{/let}}
  </tr>
  */
  {
    "id": "DrNDMHvD",
    "block": "[[[10,\"tr\"],[12],[1,\"\\n\"],[44,[[28,[37,1],null,[[\"shouldAddExtraColumn\",\"RowSortingCell\"],[[30,0,[\"shouldAddExtraColumn\"]],[50,[28,[37,3],[[30,1,[\"rowSortingCellComponent\"]]],null],0,null,[[\"themeInstance\",\"selectedItems\",\"expandedItems\",\"sortProperties\",\"sort\",\"data\",\"expandAllRows\",\"collapseAllRows\",\"toggleAllSelection\"],[[30,1],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9]]]]]]]],[[[41,[48,[30,14]],[[[1,\"      \"],[18,14,[[30,10]]],[1,\"\\n\"]],[]],[[[41,[30,0,[\"shouldAddExtraColumn\"]],[[[1,\"        \"],[10,\"th\"],[12],[1,\"\\n\"],[41,[30,11],[[[1,\"            \"],[46,[28,[37,3],[[30,11]],null],null,[[\"currentGroupingPropertyName\"],[[30,12]]],null],[1,\"\\n\"]],[]],[[[1,\"            \"],[1,[30,0,[\"currentGroupingPropertyNameTitlelized\"]]],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,8],[[28,[37,8],[[30,0,[\"shownColumns\"]]],null]],null],null,[[[1,\"        \"],[8,[30,10,[\"RowSortingCell\"]],null,[[\"@column\"],[[30,13]]],null],[1,\"\\n\"]],[13]],null]],[]]]],[10]]],[13]],[\"@themeInstance\",\"@selectedItems\",\"@expandedItems\",\"@sortProperties\",\"@sort\",\"@data\",\"@expandAllRows\",\"@collapseAllRows\",\"@toggleAllSelection\",\"RowSorting\",\"@groupHeaderCellComponent\",\"@currentGroupingPropertyName\",\"column\",\"&default\"],false,[\"let\",\"hash\",\"component\",\"ensure-safe-component\",\"if\",\"has-block\",\"yield\",\"each\",\"-track-array\"]]",
    "moduleName": "ember-models-table/components/models-table/themes/default/row-sorting.hbs",
    "isStrictMode": false
  });
  /**
   * Table header item used within {@link DefaultTheme.TableHeader | TableHeader}.
   *
   * Component generates tr with column titles in the separated cells. Click by each cell will sort table data by selected field.
   * Check properties {@link Core.ModelsTableColumn.disableSorting} and {@link Core.ModelsTableColumn.sortedBy}.
   *
   * Usage example:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowSorting />
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * Usage with a block context:
   *
   * ```html
   * <ModelsTable @data={{this.data}} @columns={{this.columns}} as |MT|>
   *   <MT.Table as |Table|>
   *     <Table.Header as |Header|>
   *       <Header.RowSorting as |RS|>
   *         {{#each MT.visibleProcessedColumns as |column|}}
   *           <td>{{column.columnTitle}}</td>
   *         {{/each}}
   *       </Header.RowSorting>
   *       {{! ... }}
   *     </Table.Header>
   *     {{! ... }}
   *   </MT.Table>
   *   {{! .... }}
   * </ModelsTable>
   * ```
   *
   * ModelsTableRowSorting yields references to the following contextual components:
   *
   * * {@link DefaultTheme.RowSortingCell | RowSortingCell} - component used as sorting row cell. Clicking on it causes column sorting
   *
   * References to the following properties are yielded:
   *
   * * {@link shouldAddExtraColumn} - determines if extra column should be added to the row in the `thead`. It happens when rows grouping is used and extra column with group values exists
   *
   * Check own docs for each component to get detailed info.
   */
  class RowSorting extends _component2.default {
    get shownColumns() {
      return (0, _macros.shownColumnsBody)(this.args.processedColumns, 'colspanForSortCell');
    }

    /**
     * @default false
     */
    get shouldAddExtraColumn() {
      return this.args.displayGroupedValueAs === 'column' && this.args.useDataGrouping && !!this.args.visibleProcessedColumns.length;
    }
    get currentGroupingPropertyNameTitlelized() {
      return (0, _emtColumn.propertyNameToTitle)(this.args.currentGroupingPropertyName);
    }
  }
  _exports.default = RowSorting;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, RowSorting);
});